import {
  LICENSES_ENTITY,
  ALL_LICENSES_ENTITY,
  LICENSES_BY_ID_ENTITY,
  API_KEYS_ENTITY,
} from 'grb-admin-console/Entities';

const USER_API_KEYS_ENTITY = 'userApiKeys';
const USERS_ENTITY = 'users';
const NODES_ENTITY = 'nodes';
const SETTINGS_ENTITY = 'settings';

export {
  USER_API_KEYS_ENTITY,
  API_KEYS_ENTITY,
  LICENSES_ENTITY,
  ALL_LICENSES_ENTITY,
  LICENSES_BY_ID_ENTITY,
  USERS_ENTITY,
  NODES_ENTITY,
  SETTINGS_ENTITY,
};
