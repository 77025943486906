import { API_KEYS_ENTITY } from '../Entities';

const API_KEY_TYPENAME = 'ApiKey';

const ACTION_CREATE_USER_API_KEY = 'createUserAPIKey';
const ACTION_DELETE_USER_API_KEY = 'deleteUserAPIKey';
const ACTION_DISABLE_USER_API_KEY = 'disableUserAPIKey';
const ACTION_EDIT_USER_API_KEY = 'editUserAPIKey';

class APIKeyService {
  constructor(config, store, jsonConfig) {
    this.store = store;
    this.onError = config.onError;
    this.authService = config.authService;
    this.apiClient = config.apiClient;
    const { uri = '/auth/keys', type = API_KEY_TYPENAME, entity = API_KEYS_ENTITY } = jsonConfig || {};
    this.uri = uri;
    this.entity = entity;
    const { schemaService } = config;
    this.store = store;

    // Register the api keys entity to the store
    store.addEntity(entity, {
      request: context => this.apiClient.get(`${this.uri}${context.query || ''}`),
      polling: jsonConfig && jsonConfig.polling,
      type: schemaService.getType(type),
      defaultValue: [],
    });
  }

  validateCreateApiKey() {
    return this.authService.canPerformAction(ACTION_CREATE_USER_API_KEY);
  }

  updateAPIKeysUntil(condition) {
    const check = (ctx) => {
      this.store.update(this.entity).then((keys) => {
        const verified = keys && condition(keys);
        ctx.count -= 1;
        if (keys && !verified && ctx.count) {
          setTimeout(() => {
            check(ctx);
          }, ctx.delay);
        }
      });
    };
    check({ count: 5, delay: 1000 });
  }

  createApiKey(params = {}) {
    return this.apiClient.post(this.uri, params)
      .then((res) => {
        const key = res.data && res.data.key;
        this.updateAPIKeysUntil((keys) => {
          return !key || keys.find(k => k.key === key);
        });
        return res.data;
      });
  }

  validateDeleteApiKey(key) {
    return this.authService.canPerformAction(ACTION_DELETE_USER_API_KEY, key, key.userId);
  }

  deleteApiKey(key) {
    return this.apiClient.delete(`${this.uri}/${key.key}`)
      .then((res) => {
        this.updateAPIKeysUntil((keys) => {
          return !keys.find(k => k.key === key.key);
        });
        return res;
      });
  }

  validateGenerateApiKeySecret() {
    return null;
  }

  generateApiKeySecret(key) {
    return this.apiClient.post(`${this.uri}/${key.key}`, {})
      .then((res) => {
        this.store.update(this.entity);
        return res.data;
      });
  }

  getDownloadLicenseURL(key) {
    return `${this.uri}/${key.key}/licensefile`;
  }

  validateDisableKey(key) {
    return this.authService.canPerformAction(ACTION_DISABLE_USER_API_KEY, key, key.userId);
  }

  disableApiKey(key, enable) {
    const method = enable ? 'post' : 'delete';
    return this.apiClient[method](`${this.uri}/${key.key}/active`)
    .then((res) => {
      this.updateAPIKeysUntil((keys) => {
        const nk = keys.find(k => k.key === key);
        return nk && (nk.disabled === !enable);
      });
      return res.data;
    });
  }

  validateEditApiKey(key) {
    return this.authService.canPerformAction(ACTION_EDIT_USER_API_KEY, key, key.userId);
  }

  editApiKey(key, params) {
    return this.apiClient.put(`${this.uri}/${key.key}`, params)
    .then((res) => {
      this.updateAPIKeysUntil((keys) => {
        const nk = keys.find(k => k.key === key);
        return nk && !Object.keys(params).find(k => (params[k] !== nk[k]));
      });
      return res.data;
    });
  }

  //
  // Generating license file for a generated API Key
  fetchLicenseDownloadURL(apiKey) {
    const header = `# Gurobi Cluster Manager license file
# Your credentials are private and should not be shared or copied to public repositories.`;

    let managerURL = window.location.origin;
    if (!managerURL) {
      // Opera browsers
      managerURL = `${window.location.protocol}//${window.location.host}`;
    }
    const managerLine = `CSMANAGER=${managerURL}`;
    const appLine = apiKey.appName ? `CSAPPNAME=${apiKey.appName}\n` : '';
    const content = `${header}\n${managerLine}\nCSAPIACCESSID=${apiKey.key}\nCSAPISECRET=${apiKey.secret}\n${appLine}`;
    const blob = new Blob([content], { type: 'application/octet-stream' });
    return Promise.resolve(URL.createObjectURL(blob));
  }

  revokeLicenseDownloadURL(blobURL) {
    URL.revokeObjectURL(blobURL);
  }
}

export default APIKeyService;
