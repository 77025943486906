import Cookies from 'js-cookie';
import AuthService from 'tuc-webui-base/services/AuthService';

const ACCEPT_ALL = () => true;
const READ_ONLY_ROLE = 'READONLY';
const SYSADMIN_ROLE = 'SYSADMIN';
const ADMIN_ROLE = 'ADMIN';
const ROLES = [
  READ_ONLY_ROLE,
  'STANDARD',
  ADMIN_ROLE,
  SYSADMIN_ROLE,
];

const PROVIDER_GRB = 1;
const PROVIDER_LDAP = 2;
// const PROVIDER_SAML = 3;

const ROLE_FUNCTIONS = {
  sameUserId: (actionId, profile, obj, objOwner) => {
    return profile.id === objOwner;
  },
  differentUserId: (actionId, profile, obj, objOwner) => {
    return profile.id !== objOwner;
  },
};

export default class GrbrsmAuthService extends AuthService {
  constructor(config, props) {
    super(config, props);
    this.apiClient = config.apiClient;
    this.initialized = false;
    this.init = this.initialize();
    this.subscribers = [];

    const UNAUTHORIZED = 401;
    this.apiClient.interceptors.response.use(
      response => response,
      (error) => {
        if (!error.response) {
          return Promise.reject(new Error('Network error'));
        }
        const { status } = error.response;
        if (status === UNAUTHORIZED) {
          // Specific case when this error occurs when trying to change password
          if (error.config && (error.config.url === '/auth/profile/password')) {
            return Promise.reject(error);
          }
          this.profile = undefined;
          if (this.initialized) {
            this.subscribers.forEach((subscriber) => {
              subscriber({ isAuth: false });
            });
          }
        }
        return Promise.reject(error);
     },
    );
    // Initialize permissions
    const { jsonConfig } = props || {};
    this.permissions = {};
    if (jsonConfig && jsonConfig.permissions) {
      Object.keys(jsonConfig.permissions).forEach((actionId) => {
        this.permissions[actionId] = this.parseActionPermissions(jsonConfig.permissions[actionId]);
      });
    }
  }

  isInitialized() {
    return this.initialized;
  }

  whenInitialized() {
    return this.init;
  }

  isAuthenticated() {
    return Boolean(this.profile);
  }

  acceptRoute(route) {
    return !route.roles || (this.profile && Boolean(route.roles.find(role => role === this.profile.role)));
  }

  getLastUsername() {
    return Cookies.get('username');
  }

  getProfile() {
    return this.profile;
  }

  getUsername() {
    return this.profile && this.profile.username;
  }

  getRole(profileParam) {
    const profile = profileParam || this.profile;
    return ROLES[profile.role || 0];
  }

  getRoles() {
    return ROLES;
  }

  isAdminRole(profileParam) {
    const { role } = profileParam || this.profile || {};
    return (role !== undefined) && ((ROLES[role] === SYSADMIN_ROLE) || (ROLES[role] === ADMIN_ROLE));
  }

  isReadOnlyRole(profileParam) {
    const { role } = profileParam || this.profile || {};
    return (role !== undefined) && (ROLES[role] === READ_ONLY_ROLE);
  }

  getFirstname() {
    return this.profile && this.profile.firstname;
  }

  getLastname() {
    return this.profile && this.profile.lastname;
  }

  getEmail() {
    return this.profile && this.profile.email;
  }

  authenticate(username, password, admin) {
    return this.apiClient.post('/auth/login', {
      username,
      password,
      sysAdminLogin: admin || false,
    })
    .then((res) => {
      Cookies.set('username', res.data.username);
      this.profile = res.data;
      this.subscribers.forEach((subscriber) => {
        subscriber({ isAuth: true });
      });
      return res.data;
    });
  }

  validateEditProfile(profileParam) {
    if (this.provider !== PROVIDER_LDAP) {
      return null;
    }
    const p = profileParam || this.profile || {};
    if (p.tokenDisabled) {
      return null;
    }
    const { role } = profileParam || this.profile || {};
    return (role !== undefined) && (ROLES[role] === SYSADMIN_ROLE)
      ? null : 'This profile is managed in the LDAP repository';
  }

  changePassword(oldpassword, password) {
    return this.apiClient.post('/auth/profile/password', {
      oldpassword,
      password,
    })
    .then(() => {
      return true;
    });
  }

  updateProfile(newProfile) {
    // console.log(`New profile ${JSON.stringify(newProfile, null, 4)}`);
    // return Promise.resolve(newProfile).then(() => {
    //   this.profile = Object.assign({}, this.profile, newProfile);
    // });
    return this.apiClient.put('/auth/profile', newProfile).then(() => {
      this.profile = Object.assign({}, this.profile, newProfile);
      this.profile.role = this.profile.role || 0;
      return this.profile;
    });
  }

  logout() {
    return this.apiClient.post('/auth/logout')
    .then(() => {
      Cookies.set('username', '');
      this.profile = undefined;
      this.subscribers.forEach((subscriber) => {
        subscriber({ isAuth: false });
      });
      return {};
    });
  }

  initialize() {
    return this.apiClient.get('/auth/config').then((res) => {
      this.provider = res.data.provider;
      return this.provider;
    })
    .catch(() => {
      this.provider = PROVIDER_GRB;
    })
    .then(() => {
      return this.loadProfile();
    });
  }

  isGrbProvider() {
    return this.provider === PROVIDER_GRB;
  }

  loadProfile() {
    return this.apiClient.get('/auth/profile').then((res) => {
      this.profile = { ...res.data, role: res.data.role || 0 };
      this.initialized = true;
      return res.data;
    })
    .catch(() => {
      this.profile = undefined;
      this.initialized = true;
    });
  }

  subscribe(onChange) {
    this.subscribers.push(onChange);
    return onChange;
  }

  unsubscribe(onChange) {
    const index = this.subscribers.indexOf(onChange);
    if (index >= 0) {
      this.subscribers.splice(index, 1);
    }
  }

  //
  // Tour
  //
  isSkipTour(p) {
    const profile = p || this.profile;
    return !profile || profile.skipTour;
  }

  setSkipTour(profile, skipTour) {
    return this.updateProfile({ skipTour });
  }

  //
  // Action permissions
  //
  canPerformAction(actionId, obj, objOwner) {
    return !this.permissions[actionId]
        ? null : this.permissions[actionId](actionId, this.profile, obj, objOwner);
  }

  parseRolePermission(rolePermission) {
    const type = typeof rolePermission;
    if (type === 'function') {
      return rolePermission;
    }
    if (rolePermission.function) {
      if (!ROLE_FUNCTIONS[rolePermission.function]) {
        console.log(`Unkown role function ${rolePermission}`);
        return () => false;
      }
      return ROLE_FUNCTIONS[rolePermission.function];
    }
    console.log(`Cannot parse role permission ${JSON.stringify(rolePermission)}`);
    return undefined;
  }

  parseActionPermissions(permissionCfg) {
    const cfgType = typeof permissionCfg;
    if (cfgType === 'function') {
      return permissionCfg;
    }
    const rolePermissions = {
      [SYSADMIN_ROLE]: ACCEPT_ALL,
    };
    const addPermission = (role, fct, message) => {
      const permFct = message ? (id, profile, obj, objOwner) => fct(id, profile, obj, objOwner) || message : fct;
      if (!rolePermissions[role]) {
        rolePermissions[role] = permFct;
      } else {
        const prevPerm = rolePermissions[role];
        rolePermissions[role] = (id, profile, obj, objOwner) => {
          const result = prevPerm(id, profile, obj, objOwner);
          return result === true ? permFct(id, profile, obj, objOwner) : result;
        };
      }
    };
    if (cfgType === 'string') {
      addPermission(permissionCfg, ACCEPT_ALL);
    } else if (Array.isArray(permissionCfg)) {
      permissionCfg.forEach((cfg) => {
        if (typeof cfg === 'string') {
          addPermission(cfg, ACCEPT_ALL);
        } else {
          addPermission(cfg.role, this.parseRolePermission(cfg), cfg.message);
        }
      });
    }
    if (!rolePermissions[SYSADMIN_ROLE]) {
      rolePermissions[SYSADMIN_ROLE] = ACCEPT_ALL;
    }
    return (actionId, profile, obj, objOwner) => {
      // const role = ROLES[profile.role];
      // const fct = rolePermissions[role];
      // return fct ? fct(actionId, profile, obj, objOwner) : false;
      // Find closest permission
      let fct;
      for (let i = profile.role; (i >= 0) && !fct; i -= 1) {
        fct = rolePermissions[ROLES[i]];
      }
      if (!fct) {
        return 'Current user does not have the rights to perform this action.';
      }
      const r = fct(actionId, profile, obj, objOwner);
      return r === true ? null : r;
    };
  }
}
