import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import NodeIcon from '@material-ui/icons/RadioButtonUnchecked';
import ComputingIcon from '@material-ui/icons/Settings';
import FailedIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import './NodeStatus.scss';

const ALIVE = 1;
const FAILED = 2;
const JOINING = 3;
const LEAVING = 4;
const DEGRADED = 5;

const Tooltips = [];
Tooltips[ALIVE] = 'ALIVE';
Tooltips[FAILED] = 'FAILED';
Tooltips[JOINING] = 'JOINING';
Tooltips[LEAVING] = 'LEAVING';
Tooltips[DEGRADED] = 'DEGRADED';

const StatusClassnames = [];
StatusClassnames[ALIVE] = 'status-alive';
StatusClassnames[FAILED] = 'status-failed';
StatusClassnames[JOINING] = 'status-joining';
StatusClassnames[LEAVING] = 'status-leaving';
StatusClassnames[DEGRADED] = 'status-degraded';

const NodeStatus = ({ value, object: node }) => {
  // value = LEAVING; // eslint-disable-line
  let Icon;
  const computing = (value === ALIVE) && node.metrics ? (node.metrics.nbRunningJobs || node.metrics.nbQueuedJobs) : false;
  switch (value) {
    case ALIVE:
      Icon = computing ? <ComputingIcon /> : <NodeIcon />;
      break;
    case FAILED:
      Icon = <FailedIcon />;
      break;
    case JOINING:
      Icon = <CircularProgress size={22} />;
      break;
    case LEAVING:
      Icon = <CircularProgress size={22} />;
      break;
    default:
      Icon = <NodeIcon />;
      break;
  }
  return (
    <Tooltip
      title={Tooltips[value]}
    >
      <div className={classnames('grbrsm-node-status', StatusClassnames[value], computing && 'grb-rotate')}>
        {Icon}
      </div>
    </Tooltip>
  );
};

NodeStatus.propTypes = {
  value: PropTypes.number,
  object: PropTypes.shape({}),
};

NodeStatus.defaultProps = {
  value: 0,
  object: undefined,
};

export default NodeStatus;
