import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import PasswordCheckedIcon from '@material-ui/icons/Check';
import { useStoreData } from 'grb-webui/store';
import PasswordValidationRules from '../PasswordValidationRules';
import { SETTINGS_ENTITY } from '../../Entities';

import './ChangePasswordDialog.scss';

const focusInputField = (input) => {
  if (input) {
    setTimeout(() => { input.focus(); }, 100);
  }
};

const PasswordCheck = ({ hidden, disabled }) => {
  return (
    <PasswordCheckedIcon
      className={classnames(
        'grb-gutter-left',
        'grb-password-checked',
        hidden && 'grb-hidden',
        disabled && 'grb-disabled',
      )}
    />
  );
};

PasswordCheck.propTypes = {
  hidden: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

PasswordCheck.defaultProps = {
  disabled: false,
};

class ChangePasswordDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  getInitialState() {
    return this.validateState({
      originalPassword: '',
      newPassword1: '',
      newPassword2: '',
      processing: false,
      cancelling: false, // http://github.compute.gurobi.com/Gurobi/grbrs-ng/issues/418
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      // Update the default values and validation errors each time the dialog is showing
      // Example for using this method for this use case can be found here:
      // https://reactjs.org/docs/react-component.html#componentdidupdate
      this.setInitialState();
    }
  }

  handleChange = name => (event) => {
    const eventToValue = (e) => {
      return e && e.target ? e.target.value : e;
    };
    const value = eventToValue(event);
    const { state } = this;
    this.setState(this.validateState({
      ...state,
      [name]: value,
    }));
  };

  validateState(state) {
    const { newPassword1, originalPassword, newPassword2 } = state;
    return {
      ...state,
      originalPasswordError: !originalPassword ? 'Must not be empty' : '',
      passwordIdenticalError: originalPassword === newPassword1 ? 'Must be different from original password' : '',
      passwordError: this.props.settingsService.validatePassword(newPassword1, this.props.settings),
      confirmedPassowrdError: newPassword2 !== newPassword1
        ? 'Confirmed password does not match password' : '',
      serverError: '',
    };
  }

  handleCancel() {
    this.setState(Object.assign({}, this.getInitialState(), { cancelling: true }), () => {
      this.props.onClose(null);
    });
  }

  handleOk() {
    const { authService } = this.props;
    this.setState({ processing: true });
    authService.changePassword(this.state.originalPassword, this.state.newPassword1)
      .then(() => {
        const newPassword = this.state.newPassword1;
        this.setState(this.getInitialState(), () => {
          this.props.onClose(newPassword);
        });
      })
      .catch((err) => {
        let msg;
        if (!err.response || !err.response.data || !err.response.data.error) {
          msg = `${err}`;
        } else {
          const { error } = err.response.data;
          msg = `[${error.code}] ${error.message}`;
        }
        this.setState({ serverError: msg, processing: false });
      });
  }

  setInitialState() {
    this.setState(this.getInitialState());
  }

  render() {
    const { open, authService } = this.props;
    const {
      serverError,
      passwordError,
      originalPasswordError,
      confirmedPassowrdError,
      passwordIdenticalError,
      originalPassword,
      newPassword1,
      newPassword2,
      processing,
      cancelling,
    } = this.state;
    const inputType = cancelling && 'text' || 'password';
    const inputStyle = cancelling && { visibility: 'hidden' } || {};
    const username = authService.getUsername();
    return (
      <Dialog
        disableBackdropClick
        aria-labelledby="update-password-dialog-title"
        className="grb-update-password-dialog"
        onClose={this.handleCancel}
        id="grbrsm-change-password-dialog"
        open={open}
      >
        <DialogTitle id="update-password-dialog-title">Update password</DialogTitle>
        <DialogContent>
          {serverError && (
            <div className="grb-error grbrsm-password-error">
              <Typography variant="body1">
                {serverError}
              </Typography>
            </div>
          )}
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <FormControl margin="normal" required fullWidth style={{ display: 'none' }}>
              <InputLabel htmlFor="username">Username</InputLabel>
              <Input
                id="username"
                name="username"
                value={username}
              />
            </FormControl>
            <div className="grb-checked-password-field">
              <FormControl margin="normal" required fullWidth error={!!originalPasswordError}>
                <InputLabel htmlFor="originalPassword">Original Password</InputLabel>
                <Input
                  id="originalPassword"
                  name="originalPassword"
                  type={inputType}
                  autoFocus
                  inputRef={focusInputField}
                  value={originalPassword}
                  onChange={this.handleChange('originalPassword')}
                  disabled={processing}
                  style={inputStyle}
                  inputProps={{ autoComplete: 'current-password' }}
                />
                {!!originalPasswordError && (
                  <FormHelperText>{originalPasswordError}</FormHelperText>
                )}
              </FormControl>
              <PasswordCheck hidden />
            </div>
            <div className="grb-checked-password-field">
              <FormControl
                margin="normal"
                required
                fullWidth
                error={!originalPasswordError && !!passwordIdenticalError}
              >
                <InputLabel htmlFor="newPassword1">New Password</InputLabel>
                <Input
                  id="newPassword1"
                  name="newPassword1"
                  type={inputType}
                  value={newPassword1}
                  onChange={this.handleChange('newPassword1')}
                  disabled={processing}
                  style={inputStyle}
                  inputProps={{ autoComplete: 'new-password' }}
                />
                {!originalPasswordError && !!passwordIdenticalError && (
                  <FormHelperText>{passwordIdenticalError}</FormHelperText>
                )}
              </FormControl>
              <PasswordCheck hidden={!!passwordError} />
            </div>
            {!originalPasswordError && !passwordIdenticalError && !!passwordError && (
              <PasswordValidationRules
                password={newPassword1}
                settings={this.props.settings}
                settingsService={this.props.settingsService}
              />
            )}
            <div className="grb-checked-password-field">
              <FormControl
                margin="normal"
                required
                fullWidth
                error={!originalPasswordError && !passwordIdenticalError && !passwordError && !!confirmedPassowrdError}
              >
                <InputLabel htmlFor="newPassword2">Confirm Password</InputLabel>
                <Input
                  id="newPassword2"
                  name="newPassword2"
                  type={inputType}
                  value={newPassword2}
                  onChange={this.handleChange('newPassword2')}
                  disabled={processing}
                  style={inputStyle}
                  inputProps={{ autoComplete: 'new-password' }}
                />
                {!originalPasswordError && !passwordIdenticalError && !passwordError && !!confirmedPassowrdError && (
                  <FormHelperText>{confirmedPassowrdError}</FormHelperText>
                )}
              </FormControl>
              <PasswordCheck hidden={!!confirmedPassowrdError || !newPassword2} />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            className="grbrsm-change-password-button"
            disabled={passwordError || confirmedPassowrdError || processing}
            onClick={this.handleOk}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ChangePasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  authService: PropTypes.shape({
    changePassword: PropTypes.func.isRequired,
    getUsername: PropTypes.func.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  settingsService: PropTypes.shape({
    validatePassword: PropTypes.func,
  }).isRequired,
  settings: PropTypes.shape({}).isRequired,
};

export default (props) => {
  const pps = props;
  const { settings } = useStoreData(pps.store, [SETTINGS_ENTITY]);
  return settings
    ? <ChangePasswordDialog {...props} settings={settings} />
    : (null);
};
