import {
  SETTINGS_ENTITY,
} from '../Entities';

const SETTINGS_TYPENAME = 'Settings';

const ACTION_UPDATE_SETTINGS = 'updateSettings';

// eslint-disable-next-line no-useless-escape
const SYMBOLS = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

class SettingsService {
  constructor(config, store, options) {
    this.store = store;
    this.onError = config.onError;
    this.authService = config.authService;
    this.apiClient = config.apiClient;
    this.apiClientPrefix = config.apiClientPrefix;
    const { schemaService } = config;

    const { uri = '/settings' } = options;
    this.uri = uri;

    // Register the settings entity to the store
    store.addEntity(SETTINGS_ENTITY, {
      request: context => (this.apiClient.get(`${uri}${context.query || ''}`)),
      type: schemaService.getType(SETTINGS_TYPENAME),
      defaultValue: undefined,
    });
  }

  validateUpdateSettings(settings) {
    return this.authService.canPerformAction(ACTION_UPDATE_SETTINGS, settings);
  }

  processSettings(settings) {
    // http://github.compute.gurobi.com/Gurobi/grbrs-ng/issues/1145
    // port property of ldapSettings must be a string, not a number
    return settings.providerSettings && settings.providerSettings.ldapSettings
    ? {
      ...settings,
      providerSettings: {
        ...settings.providerSettings,
        ldapSettings: {
          ...settings.providerSettings.ldapSettings,
          port: (settings.providerSettings.ldapSettings.port) || (settings.providerSettings.ldapSettings.port === 0)
            ? settings.providerSettings.ldapSettings.port.toString()
            : undefined,
        },
      },
    } : settings;
  }

  updateSettings(settings) {
    const pSettings = this.processSettings(settings);
    return this.apiClient.put(this.uri, pSettings)
      .then((res) => {
        this.store.update(SETTINGS_ENTITY);
        return res.data;
      });
  }

  test(settings) {
    const pSettings = this.processSettings(settings);
    return this.apiClient.post('settings/provider/test', pSettings).then(({ data }) => data);
  }

  passwordRules(settings) {
    const rules = [];
    const { minLength, maxLength, upper, lower, digits, symbols } = settings.passwordPolicy;
    if (minLength > 0) {
      rules.push({
        message: `Must contain at least ${minLength} characters`,
        id: 'minLength',
        validate: pwd => (pwd && (pwd.length >= minLength)),
      });
    } else {
      rules.push({
        message: 'Must not be empty',
        id: 'notEmpty',
        validate: pwd => (pwd && (pwd.length >= minLength)),
      });
    }
    if (maxLength > 0) {
      rules.push({
        message: `Must not exceed ${maxLength} characters`,
        id: 'maxLength',
        validate: pwd => (!pwd || (pwd.length <= maxLength)),
      });
    }
    if (upper) {
      rules.push({
        message: 'Must contain at least one uppercase letter',
        id: 'upper',
        validate: pwd => (pwd && /[A-Z]/.test(pwd)),
      });
    }
    if (lower) {
      rules.push({
        message: 'Must contain at least one lowercase letter',
        id: 'lower',
        validate: pwd => (pwd && /[a-z]/.test(pwd)),
      });
    }
    if (digits) {
      rules.push({
        message: 'Must contain at least one digit',
        id: 'digits',
        validate: pwd => (pwd && /[0-9]/.test(pwd)),
      });
    }
    if (symbols) {
      rules.push({
        message: 'Must contain at least one special character',
        id: 'symbols',
        validate: pwd => (pwd && SYMBOLS.test(pwd)),
      });
    }
    return rules;
  }

  validatePassword(password, settings) {
    if (!password || !password.length) {
      return 'Must not be empty';
    }
    const { minLength, maxLength, upper, lower, digits, symbols } = settings.passwordPolicy;
    if ((minLength > 0) && (password.length < minLength)) {
      return `Must contain at least ${minLength} characters`;
    }
    if ((maxLength > 0) && (password.length > maxLength)) {
      return `Cannot exceed ${maxLength} characters`;
    }
    if (upper && !/[A-Z]/.test(password)) {
      return 'Must contain at least one uppercase letter';
    }
    if (lower && !/[a-z]/.test(password)) {
      return 'Must contain at least one lowercase letter';
    }
    if (digits && !/[0-9]/.test(password)) {
      return 'Must contain at least one digit';
    }
    if (symbols && !SYMBOLS.test(password)) {
      return 'Must contain at least one special symbol';
    }
    return null;
  }
}

export default SettingsService;
