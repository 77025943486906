import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import PasswordCheckedIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Close';

import './PasswordValidationRules.scss';

const PasswordValidationRules = ({ password, settingsService, settings }) => {
  return (
    <ul className="grb-password-rules">
      {settingsService.passwordRules(settings).map((rule) => {
        const valid = rule.validate(password);
        return (
          <li
            className={classnames(
              'grb-password-rule',
              valid ? 'grb-password-rule-valid' : 'grb-password-rule-invalid',
            )}
            key={rule.id}
          >
            {valid ? <PasswordCheckedIcon style={{ fontSize: '16px' }} /> : <ErrorIcon style={{ fontSize: '16px' }} />}
            <Typography variant="body2">{rule.message}</Typography>
          </li>
        );
      })}
    </ul>
  );
};

PasswordValidationRules.propTypes = {
  password: PropTypes.string,
  settingsService: PropTypes.shape({
    passwordRules: PropTypes.func,
  }).isRequired,
  settings: PropTypes.shape({}).isRequired,
};

PasswordValidationRules.defaultProps = {
  password: undefined,
};

export default PasswordValidationRules;
