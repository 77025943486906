import React from 'react';
import Typography from '@material-ui/core/Typography';
import AppMainPage, { FIT_TO_PAGE_VARIANT } from 'grb-admin-console/components/AppMainPage';
import APIKeysContainer from 'grb-admin-console/pages/keys';
import NewCreatedAPIKeyDialog from 'grb-admin-console/components/NewCreatedAPIKeyDialog';
import DownloadLicenseDialog from 'grb-admin-console/components/DownloadLicenseDialog';
import { USER_API_KEYS_ENTITY } from '../../Entities';

import './UserAPIKeysContainer.scss';

const UserAPIKeysContainer = ({ getApiKeyService, authService, ...options }) => {
  const UserKeyCreatedDialog = (params) => {
    const DownloadDialog = DownloadLicenseDialog(params);
    let ReadOnlyDialog;
    return (props) => {
      if (authService.isReadOnlyRole()) {
        if (!ReadOnlyDialog) {
          ReadOnlyDialog = NewCreatedAPIKeyDialog(params);
        }
        return <ReadOnlyDialog {...props} />;
      }
      return <DownloadDialog {...props} />;
    };
  };

  const APIKeys = APIKeysContainer({
    ...options,
    keysEntity: USER_API_KEYS_ENTITY,
    backTo: true,
    created: {
      dialog: UserKeyCreatedDialog,
    },
  });
  return (props) => {
    const profile = authService.getProfile();
    if (profile && profile.apiKeysDisabled) {
      return (
        <AppMainPage
          {...props}
          variant={FIT_TO_PAGE_VARIANT}
          className="grb-api-keys-page"
          backTo
        >
          <div className="grb-user-api-keys-message">
            <Typography variant="h6">
              You do not have permission to define and use API keys.
            </Typography>
            <Typography variant="h6">
              Please contact the system administrator.
            </Typography>
          </div>
        </AppMainPage>
      );
    }
    return (
      <APIKeys {...props} apiKeyService={getApiKeyService()} />
    );
  };
};

export default UserAPIKeysContainer;
