import React from 'react';
import PropTypes from 'prop-types';
import ChartIcon from '@material-ui/icons/ShowChart';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import GoogleChart from 'tuc-webui-base/components/GoogleChart';
import { DurationFormatter } from 'tuc-webui-base/services/Formatters';
import { GraphTile } from 'grb-webui/components/GraphDashboard';
import './JobObjectiveDashboardTile.scss';

const jobId = job => job && job.id;
const NUMBER_FORMATTER = new Intl.NumberFormat();

const NO_MODEL_INDEX = -1;
const modelIndex = job => (job && job.modelInfo ? job.modelInfo.index : NO_MODEL_INDEX);

const MIP_OBJECTIVE_CHART = 'mip-objective';
const MIP_GAP_CHART = 'mip-gap';
const OBJECTIVE_CHART = 'objective';

const createChartModel = () => {
  // Data is to be sent to GoogleCharts.api.visualization.DataTable
  // Column titles are provided with this component configuration that is passed to the GoogleChart
  // component with the configuration property.
  // Data rows have the format:
  //   row[0] = { v: time as seconds, f: formatted time }
  //   row[n > 0] = { v: objective/gap value, f: Intl.NumberFormat of the v value }
  //   if row.dummy:
  //       Temporary row added with ts === current time to show the chart is
  //       still actively being fed, the job is the still running
  const data = [];
  // Google stepped area charts have no options to determine whether a step should take its value
  // from the left or right row. We want the left row, Google uses the right one.
  // We use this workaround instead
  let pendingRow;

  const DURATION_OPTIONS = { unit: 'seconds' };
  const model = {
    data,
    setTimeValues: (ts, ...values) => {
      // ignore empty and infinity values
      const pvalues = values.map(v => (!v || (Math.abs(v) >= 1e+100) ? undefined : v));
      if (pvalues.find(v => (v === undefined))) {
        return;
      }

      const newRow = [
        { v: ts, f: DurationFormatter(ts, undefined, undefined, DURATION_OPTIONS) },
        ...pvalues.map(v => ({ v, f: NUMBER_FORMATTER.format(v) }))];
      // look at the last value
      if (pendingRow) {
        if (pendingRow[0].v > ts) { // ts in the past, ignore
          return;
        }
        // same values, ignore
        if (!pendingRow.find((o, i) => i && (o.v !== pvalues[i - 1]))) {
          return;
        }

        if (data[data.length - 1].dummy) { // was a dummy value, remove
          data.pop();
        }
        pendingRow[0].v = newRow[0].v;
        pendingRow[0].f = newRow[0].f;
        // Adding previous values with current times as new row
        data.push(pendingRow);
      } else {
        // First data row, first two rows will have same values
        data.push(newRow);
      }
      pendingRow = newRow;
    },
    closeValues: () => {
      if (pendingRow) {
        // In the last data object of the set, replace empty values
        // with the last none-empty value for the same column.
        for (let col = 1; col < pendingRow.length; col += 1) {
          if (pendingRow[col] === undefined) {
            // Find the last not empty value for this column
            for (let i = data.length - 1; i >= 0; i -= 1) {
              if (data[i][col]) {
                pendingRow[col] = data[i][col];
              }
            }
          }
        }
        data.push(pendingRow);
      }
    },
    addDummyValues: (ctx) => {
      const now = new Date().getTime();
      if (data.length > 1) {
        if (data[data.length - 1].dummy) {
          data.pop();
        }
      }
      if (pendingRow) {
        if (new Date((ctx.startedAt + (pendingRow[0].v * 1000)) < now)) {
          const d = pendingRow.slice();
          const ts = (now - ctx.startedAt) / 1000;
          d[0] = { v: ts, f: DurationFormatter(ts, undefined, undefined, DURATION_OPTIONS) };
          d.dummy = true;
          data.push(d);
        }
      }
    },
  };
  model.onUpdate = (rows, ctx) => {
    const { final } = ctx;
    rows.forEach((row) => {
      model.processRow(row, ctx);
    });
    if (final) {
      model.closeValues(ctx);
    } else {
      model.addDummyValues(ctx);
    }
    if (model.notifyUpdate) {
      model.notifyUpdate();
    }
  };
  return model;
};
const JOB_CHARTS = [
  {
    name: MIP_OBJECTIVE_CHART,
    label: 'Objective',
    matchJob: job => job.mipInfo,
    createChartModel: (job) => {
      const model = createChartModel(job);
      model.processRow = (row) => {
        if (row.obj || row.bnd) {
          model.setTimeValues(Math.floor(row.ts / 1000), row.obj, row.bnd);
        }
      };
      return model;
    },
  },
  {
    name: MIP_GAP_CHART,
    label: '%Gap',
    matchJob: job => job.mipInfo,
    createChartModel: (job) => {
      const model = createChartModel(job);
      model.mipGap = 0;
      model.processRow = (row) => {
        if (row.obj && Math.abs(row.obj) !== 1e+100 && row.bnd && Math.abs(row.bnd) !== 1e+100) {
          const gap = (Math.abs(row.obj - row.bnd) / Math.max(Math.abs(row.bnd), Math.abs(row.obj))) * 100;
          model.setTimeValues(Math.floor(row.ts / 1000), gap, model.mipGap);
        }
      };
      model.setMipGap = (mipGap) => {
        model.mipGap = mipGap;
      };
      return model;
    },
  },
  {
    name: OBJECTIVE_CHART,
    label: 'Objective',
    matchJob: job => !job.mipInfo,
    createChartModel: (job) => {
      const model = createChartModel(job);
      model.processRow = (row) => {
        if (row.obj) {
          model.setTimeValues(row.ts, row.obj);
        }
      };
      return model;
    },
  },
];

class JobObjectiveDashboardTile extends React.PureComponent {
  static getChartState(props, state, newSelectedChartName) {
    const { job, jobService } = props;

    const initCharts = () => {
      const charts = JOB_CHARTS.filter(c => c.matchJob(job));
      const preferredChartName = newSelectedChartName || state.selectedChartName;
      const selectedChartName = preferredChartName && charts.find(c => c.name === preferredChartName)
          ? preferredChartName : charts[0].name;
      const chartModel = charts.find(c => c.name === selectedChartName).createChartModel(job);
      return { charts, chartModel, selectedChartName };
    };

    if (state.job && state.chartModel) {
      jobService.unsubscribeJobMetrics(state.job, state.chartModel);
    }
    if (job) {
      const newState = {
        error: null,
        job,
        jobType: jobService.getJobAlgorithm(job),
        ...initCharts(),
        idx: modelIndex(job),
      };
      jobService.subscribeJobMetrics(job, newState.chartModel);
      newState.data = newState.chartModel.data;
      newState.chartModel.notifyUpdate = state.notifyUpdate;
      newState.chartModel.onError = state.notifyError;
      return newState;
    }
    return { job: null, chartModel: null, charts: [], selectedChartName: null };
  }

  static getDerivedStateFromProps(props, state = {}) {
    const { job, jobService } = props;
    if ((jobId(state.job) !== jobId(job))
          || (state.idx !== modelIndex(job))
          || (job && (jobService.getJobAlgorithm(job) !== state.jobType))) {
      return JobObjectiveDashboardTile.getChartState(props, state);
    }
    return null;
  }

  constructor(props) {
    super(props);

    const { configuration } = props;
    this.state = {
      idx: NO_MODEL_INDEX,
      notifyUpdate: this.notifyUpdate.bind(this),
      notifyError: (error) => {
        console.log(error);
        this.setState({ error });
      },
      chartOptions: {
        [MIP_OBJECTIVE_CHART]: configuration.configurations[MIP_OBJECTIVE_CHART],
        [MIP_GAP_CHART]: configuration.configurations[MIP_GAP_CHART],
        [OBJECTIVE_CHART]: configuration.configurations[OBJECTIVE_CHART],
      },
    };

    this.mounted = false;
    this.handleChartSelect = this.handleChartSelect.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    const { jobService } = this.props;
    if (this.state.chartModel) {
      jobService.unsubscribeJobMetrics(this.state.job, this.state.chartModel);
    }
  }

  handleChartSelect(evt) {
    if (this.state.selectedChartName !== evt.target.value) {
      this.setState((state, props) => {
        return JobObjectiveDashboardTile.getChartState(props, state, evt.target.value);
      });
    }
  }

  notifyUpdate() {
    this.setState((state) => {
      return { data: state.chartModel.data.slice() };
    });
  }

  render() {
    const { selectedChartName, charts, data, error } = this.state;
    if (!charts || !selectedChartName) {
      return <div />;
    }
    const { job } = this.props;
    const toolbar = (
      <FormControl>
        <Select
          value={selectedChartName}
          onChange={this.handleChartSelect}
          displayEmpty
          inputProps={{ 'aria-label': 'Selection of chart tyoe' }}
        >
          {charts.map(chart => (
            <MenuItem value={chart.name} key={chart.name}>{chart.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
    return (
      <GraphTile
        {...this.props}
        Icon={ChartIcon}
        title={selectedChartName ? charts.find(c => c.name === selectedChartName).label : 'Unkown'}
        id={this.props.id || 'jobObjectiveTile'}
        onChangeDisplay={this.props.onChangeDisplay}
        className="grb-job-objective-dashboard-tile"
        toolbar={toolbar}
      >
        <div className="grb-job-objective-body">
          {error ? (
            <div className="grb-job-objective-message">No data available</div>
          ) : (
            <GoogleChart
              configuration={this.state.chartOptions[selectedChartName]}
              data={data}
              key={`Chart${selectedChartName || 'None'}`} // One GoogleChart instance per chart types
            />
          )}
        </div>
      </GraphTile>
    );
  }
}

JobObjectiveDashboardTile.propTypes = {
  jobService: PropTypes.shape({
    subscribeJobMetrics: PropTypes.func,
    unsubscribeJobMetrics: PropTypes.func,
    getJobAlgorithm: PropTypes.func,
  }).isRequired,
  job: PropTypes.shape({
    id: PropTypes.string,
  }),
  id: PropTypes.string,
  style: PropTypes.shape({}),
  display: PropTypes.string,
  onChangeDisplay: PropTypes.func,
  configuration: PropTypes.shape({
    defaults: PropTypes.shape({}),
    configurations: PropTypes.shape({}),
  }),
  noHeader: PropTypes.bool,
  elevation: PropTypes.number,
};

JobObjectiveDashboardTile.defaultProps = {
  job: undefined,
  id: undefined,
  style: undefined,
  display: undefined,
  onChangeDisplay: undefined,
  configuration: undefined,
  noHeader: undefined,
  elevation: undefined,
};

export default JobObjectiveDashboardTile;
