import { USERS_ENTITY } from '../Entities';

const USER_TYPENAME = 'User';

const ACTION_CREATE_USER = 'createUser';
const ACTION_DELETE_USER = 'deleteUser';
const ACTION_DISABLE_USER = 'disableUser';
const ACTION_ENABLE_USER = 'enableUser';
const ACTION_ENABLE_USER_API_KEYS = 'enableUserAPIKeys';
const ACTION_DISABLE_USER_API_KEYS = 'disableUserAPIKeys';
const ACTION_EDIT_USER = 'editUser';
const ACTION_EDIT_USER_ROLE = 'editUserRole';
const ACTION_CHANGE_USER_PASSWORD = 'changeUserPassword';
const ACTION_RENAME_USER = 'renameUser';

class UserService {
  constructor(config, store, jsonConfig) {
    this.store = store;
    this.onError = config.onError;
    this.authService = config.authService;
    this.apiClient = config.apiClient;
    const { uri = '/users' } = jsonConfig || {};
    this.uri = uri;
    const { schemaService } = config;
    this.store = store;

    // Register the api keys entity to the store
    store.addEntity(USERS_ENTITY, {
      request: context => this.apiClient.get(`${this.uri}${context.query || ''}`),
      polling: jsonConfig && jsonConfig.polling,
      type: schemaService.getType(USER_TYPENAME),
      defaultValue: [],
    });
  }

  validateCreateUser() {
    return this.authService.canPerformAction(ACTION_CREATE_USER);
  }

  createUser(params = {}) {
    return this.apiClient.post(this.uri, params)
      .then((res) => {
        this.store.update(USERS_ENTITY);
        return res.data;
      });
  }

  validateDeleteUser(user) {
    return this.authService.canPerformAction(ACTION_DELETE_USER, user, user.id);
  }

  deleteUser(user) {
    return this.apiClient.delete(`${this.uri}/${user.id}`)
      .then((res) => {
        this.store.update(USERS_ENTITY);
        return res;
      });
  }

  validateDisableUser(user) {
    return this.authService.canPerformAction(user.disabled ? ACTION_ENABLE_USER : ACTION_DISABLE_USER, user, user.id);
  }

  disableUser(user, enable) {
    const method = enable ? 'post' : 'delete';
    return this.apiClient[method](`${this.uri}/${user.id}/active`)
    .then((res) => {
      this.store.update(USERS_ENTITY);
      return res.data;
    });
  }

  validateDisableAPIKeysUser(user) {
    return this.authService.canPerformAction(user.disabled
      ? ACTION_ENABLE_USER_API_KEYS : ACTION_DISABLE_USER_API_KEYS,
      user,
      user.id);
  }

  disableUserAPIKeys(user, enable) {
    const method = enable ? 'post' : 'delete';
    return this.apiClient[method](`${this.uri}/${user.id}/keys/active`)
    .then((res) => {
      this.store.update(USERS_ENTITY);
      return res.data;
    });
  }

  validateEditUser(user) {
    return this.authService.canPerformAction(ACTION_EDIT_USER, user, user.id);
  }

  validateEditUserRole(user) {
    return this.authService.canPerformAction(ACTION_EDIT_USER_ROLE, user, user.id);
  }

  editUser(user, params) {
    return this.apiClient.put(`${this.uri}/${user.id}`, params)
    .then((res) => {
      this.store.update(USERS_ENTITY);
      return res.data;
    });
  }

  validateChangePassword(user) {
    return this.authService.canPerformAction(ACTION_CHANGE_USER_PASSWORD, user, user.id);
  }

  changePassword(user, params) {
    return this.apiClient.post(`/users/${user.id}/password`, params)
      .then((res) => {
        this.store.update(USERS_ENTITY);
        return res.data;
      });
  }

  validateRenameUser(user) {
    return this.authService.canPerformAction(ACTION_RENAME_USER, user, user.id);
  }

  renameUser(user, params) {
    return this.apiClient.post(`/users/${user.id}/rename`, params)
      .then((res) => {
        this.store.update(USERS_ENTITY);
        return res.data;
      });
  }
}

export default UserService;
