import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EnableIcon from '@material-ui/icons/CheckCircleOutline';
import DisableIcon from '@material-ui/icons/HighlightOff';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RenameIcon from '@material-ui/icons/PermIdentity';
import PasswordIcon from '../../components/PasswordIcon';

export default (options) => {
  const isDisabled = options.isDisabled || (u => u.disabled);
  return {
    changePassword: {
      callbackProp: 'onChangePassword',
      tooltip: 'Edit API Key',
      ariaLabel: 'change user password',
      idPrefix: 'change-password-',
      icon: PasswordIcon,
      label: 'Change password',
    },
    disable: {
      callbackProp: 'onDisableUser',
      getTooltip: u => `${isDisabled(u) ? 'Enable' : 'Disable'} User`,
      ariaLabel: u => `${isDisabled(u) ? 'Enable' : 'Disable'}`,
      idPrefix: 'disable-user-',
      getIcon: u => (isDisabled(u) ? EnableIcon : DisableIcon),
      getLabel: u => `${isDisabled(u) ? 'Enable' : 'Disable'} User`,
    },
    edit: {
      callbackProp: 'onEditUser',
      tooltip: 'Edit this user',
      ariaLabel: 'edit',
      idPrefix: 'edit-user-',
      icon: EditIcon,
      label: 'Edit User',
    },
    delete: {
      callbackProp: 'onDeleteUser',
      tooltip: 'Delete this user',
      ariaLabel: 'delete',
      idPrefix: 'delete-user-',
      icon: DeleteIcon,
      label: 'Delete User',
    },
    rename: {
      callbackProp: 'onRenameUser',
      tooltip: 'Rename this user',
      ariaLabel: 'rename',
      idPrefix: 'rename-user-',
      icon: RenameIcon,
      label: 'Rename User',
    },
    menu: {
      callbackProp: 'onOpenMenu',
      tooltip: 'Open user menu',
      useEvent: true,
      idPrefix: 'menu-user-',
      icon: MoreVertIcon,
      label: 'More',
    },
  };
};
