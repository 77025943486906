import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchBar from 'tuc-webui-base/components/SearchBar';
import { extendViewService } from 'tuc-webui-base/services/ViewService';
import { useRowNumber } from 'tuc-webui-base/components/TableViewComponent';
import ConfirmDialog from 'tuc-webui-base/components/ConfirmDialog';
import MessageDialog from 'grb-webui/components/MessageDialog';
import FilterBar, { withPageFilter } from 'grb-webui/components/FilterBar';
import { TABLE_PANEL_TYPE } from 'grb-webui/ComponentTypes';
import { withStoreData } from 'grb-webui/store';
import AppMainPage, { FIT_TO_PAGE_VARIANT } from 'grb-admin-console/components/AppMainPage';
import {
  NODES_ENTITY,
} from '../../Entities';
import EditNodeDialog from './EditNodeDialog';
import NodeButtons from './NodeButtons';
import NodeStatus from './NodeStatus';
import './NodesContainer.scss';

export default (options = {}) => {
  const NodeFilter = {
    searchKey: 'search',
    propertyEditors: [],
  };

  const ViewServiceProperties = {
    componentsByName: {
      NodeButtons,
      NodeStatus,
    },
    editors: [],
  };

  const NodesContainer = (props) => {
    const {
      filter,
      arrayFilter,
      onFilterChange,
      filterEditor,
      onUpdateNodes,
      nodes,
      search,
      onSearchChange,
      configuration,
      ...rest
    } = props;
    const {
      viewService,
      nodeService,
      onSnackbarMessage,
    } = props;
    const [dialogMessage, setDialogMessage] = useState({ open: false });
    const [confirmDialog, setConfirmDialog] = useState({ open: false });
    const [TablePanel] = useState(() => viewService.getComponent(TABLE_PANEL_TYPE));
    const nodesCount = useRowNumber(nodes, false, options.serverFiltering);
    const [extViewService] = useState(() => extendViewService(viewService, ViewServiceProperties));
    const [editNodeDialog, setEditNodeDialog] = useState({ open: false });

    //
    // Toggle node
    //
    const handleToggleNode = useCallback((node, start) => {
      const toggleNode = (n) => {
        setConfirmDialog({ open: false });
        if (n) {
          const method = start ? 'startNode' : 'stopNode';
          nodeService[method](node)
          .then(() => {
            onSnackbarMessage({ message: `Node ${start ? 'started' : 'stopped'}` });
          })
          .catch(e => viewService.onError(e));
        }
      };

      const msg = start ? nodeService.validateStartNode(node) : nodeService.validateStopNode(node);
      if ((msg !== true) && (msg !== null)) {
        setDialogMessage({
          title: `${start ? 'Start' : 'Stop'} node error`,
          message: msg,
          open: true,
        });
      } else {
        setConfirmDialog({
          open: true,
          title: start ? 'Start Node' : 'Stop Node',
          message: start
            ? ' Job processing on this node will be enabled, and new jobs will be accepted.'
            : 'Job processing on this node will be disabled and new jobs will be rejected. The node will remain in DRAINING state until jobs currently running or in queue are processed.',
          buttons: [{ title: 'Cancel', value: null, id: 'cancel-toggle-node' }, { title: start ? 'Start' : 'Stop', value: node, id: 'confirm-toggle-node' }],
          onClose: toggleNode,
        });
      }
    }, [nodeService]);

    //
    // Edit node
    //
    const handleCheckEditNode = useCallback((node) => {
      const editNode = (n, config) => {
        setEditNodeDialog({ open: false });
        if (node) {
          nodeService.setNodeConfig(n, config)
          .then(() => {
            onSnackbarMessage({ message: 'Node job limit changed' });
          })
          .catch(e => viewService.onError(e));
        }
      };
      const msg = nodeService.validateEditNode(node);
      if ((msg !== true) && (msg !== null)) {
        setDialogMessage({
          title: 'Edit node error',
          message: msg,
          open: true,
        });
      } else {
        setEditNodeDialog({
          open: true,
          node,
          onClose: editNode,
        });
      }
    }, [nodeService]);

    const filteredNodes = arrayFilter ? arrayFilter(nodes) : nodes;
    //
    // Header
    //
    const HeaderBar = (
      <React.Fragment>
        {(options.filter !== false) && (
          <FilterBar
            {...rest}
            filter={filter || {}}
            filterEditor={filterEditor}
            onFilterChange={onFilterChange}
          />
        )}
        <SearchBar
          {...props}
          placeholder="Search nodes table..."
          value={search}
          onChange={onSearchChange}
        />
      </React.Fragment>
    );

    const canInteractNode = nodeService.isStartNodeAllowed();

    return (
      <AppMainPage
        route={props.route}
        variant={FIT_TO_PAGE_VARIANT}
        TrailingBar={HeaderBar}
        className="grb-nodes-page"
        rowCount={nodesCount}
        help={configuration && configuration.help}
      >
        <div className={classnames('grbrsm-node-table-panel', 'grb-fullpage', 'MuiPaper-elevation4')}>
          <TablePanel
            {...rest}
            {...rest.component}
            nodes={filteredNodes}
            viewService={extViewService}
            nodesCount={nodesCount}
            onToggleNode={handleToggleNode}
            onEditNode={handleCheckEditNode}
            canInteractNode={canInteractNode}
          />
          {dialogMessage.open && (<MessageDialog {...dialogMessage} />)}
          {confirmDialog.open && (<ConfirmDialog {...confirmDialog} maxWidth="md" />)}
          {editNodeDialog.open && (<EditNodeDialog {...editNodeDialog} />)}
        </div>
      </AppMainPage>
    );
  };

  NodesContainer.propTypes = {
    nodes: PropTypes.arrayOf(PropTypes.shape({})),
    viewService: PropTypes.shape({
      onError: PropTypes.func,
      getComponent: PropTypes.func,
    }).isRequired,
    route: PropTypes.shape({}).isRequired,
    nodeService: PropTypes.shape({
      validateEditNode: PropTypes.func,
      setNodeConfig: PropTypes.func,
      validateStartNode: PropTypes.func,
      startNode: PropTypes.func,
      validateStopNode: PropTypes.func,
      stopNode: PropTypes.func,
      isStartNodeAllowed: PropTypes.func,
    }).isRequired,
    component: PropTypes.shape({}).isRequired,
    arrayFilter: PropTypes.func,
    onFilterChange: PropTypes.func,
    filter: PropTypes.shape({}),
    filterEditor: PropTypes.shape({}),
    onSnackbarMessage: PropTypes.func.isRequired,
    onUpdateNodes: PropTypes.func,
    search: PropTypes.string,
    onSearchChange: PropTypes.func,
    configuration: PropTypes.shape({
      help: PropTypes.shape({}),
    }),
  };

  NodesContainer.defaultProps = {
    nodes: undefined,
    filter: undefined,
    arrayFilter: undefined,
    onFilterChange: undefined,
    filterEditor: undefined,
    onUpdateNodes: undefined,
    search: undefined,
    onSearchChange: undefined,
    configuration: undefined,
  };

  return withPageFilter(
    withStoreData(NodesContainer, {
      entities: [{ name: NODES_ENTITY }],
      onUpdateStore: 'onUpdateNodes',
    }),
    NodeFilter,
  );
};
