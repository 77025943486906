import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import LoginDialog from './LoginDialog';
import TestResultDialog from './TestResultDialog';

const TestButton = ({ config, formErrors, data, settingsService, viewService }) => {
  const {
    settingsPath,
    label,
    provider,
    fields,
    resultDialogTitle,
    resultSuccess,
    resultError,
  } = config;
  const useUsername = fields.indexOf('username') >= 0;
  const usePassword = fields.indexOf('password') >= 0;

  const [{ openLogin, onCloseLogin }, setLoginDialog] = useState({});
  const [{ testResults, onCloseResults }, setTestResults] = useState({});
  const [testing, setTesting] = useState(false);
  const handleTest = (credentials) => {
    const subData = Object.keys(data).filter(k => (fields.indexOf(k) >= 0))
      .reduce((all, k) => {
        all[k] = data[k];
        return all;
      }, {});
    setTesting(true);
    settingsService.test({
      providerSettings: {
        provider,
        [settingsPath]: subData,
      },
      ...((useUsername || usePassword) && credentials && { loginData: credentials } || {}),
    })
    .then((results) => {
      setTestResults({
        testResults: results,
        onCloseResults: () => {
          setTestResults({ testResults: undefined });
          setTesting(false);
        },
      });
    })
    .catch((e) => {
      viewService.onError(e);
      setTesting(false);
    });
  };

  const handleLogin = () => {
    const handleCloseLogin = (credentials) => {
      setLoginDialog({ open: false });
      if (credentials) {
        handleTest(credentials);
      } else {
        setTesting(false);
      }
    };
    setTesting(true);
    setLoginDialog({ openLogin: true, onCloseLogin: handleCloseLogin });
  };

  const hasErrors = !!formErrors
      && !!fields.find(k => (formErrors[k] && formErrors[k].length));
  return (
    <div className={`grb-ldap-test grb-ldap-test-align-${config.align}`}>
      <Button
        variant="contained"
        color="secondary"
        onClick={useUsername || usePassword ? handleLogin : handleTest}
        disabled={hasErrors || testing}
      >
        {label}
      </Button>
      {openLogin && (
        <LoginDialog
          onClose={onCloseLogin}
          title="Enter Login"
          open
          useUsername={useUsername}
          usePassword={usePassword}
        />
      )}
      {testResults && (
        <TestResultDialog
          onClose={onCloseResults}
          title="Enter Login"
          open
          results={testResults}
          dialogTitle={resultDialogTitle}
          errorTitle={resultError}
          successTitle={resultSuccess}
        />
      )}
    </div>
  );
};

TestButton.propTypes = {
  settingsService: PropTypes.shape({
    test: PropTypes.func,
  }).isRequired,
  viewService: PropTypes.shape({
    onError: PropTypes.func,
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
  config: PropTypes.shape({
    label: PropTypes.string,
    provider: PropTypes.number,
    settingsPath: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.string),
    align: PropTypes.string,
    login: PropTypes.bool,
    resultDialogTitle: PropTypes.string,
    resultSuccess: PropTypes.string,
    resultError: PropTypes.string,
  }).isRequired,
  formErrors: PropTypes.shape({}),
};

TestButton.defaultProps = {
  formErrors: undefined,
};

export default TestButton;
