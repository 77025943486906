import React from 'react';
import Typography from '@material-ui/core/Typography';
import AuthIcon from '@material-ui/icons/Group';
import ConnectionIcon from '@material-ui/icons/Link';
import FilterIcon from '@material-ui/icons/FilterList';
import LockIcon from '@material-ui/icons/Lock';
import SyncIcon from '@material-ui/icons/Sync';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SettingsPage from 'grb-admin-console/pages/settings';
import LongTextFormEditor from 'grb-webui/components/Form/LongTextFormEditor';
import PasswordIcon from '../../components/PasswordIcon';
import { SETTINGS_ENTITY } from '../../Entities';
import TestButton from './TestButton';
import './SettingsContainer.scss';

const LDAP_SETTINGS = 'ldapSettings';

const WidthIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 50 40">
    <path d="M5,20H45M12,12 4,20 12,28M38,12 46,20 38,28" stroke="#757575" strokeWidth="4" strokeLinejoin="round" strokeLinecap="round" fill="none" />
  </svg>
);

const CaseIcon = () => (
  <Typography variant="body1" style={{ paddingTop: '4px' }}>aA</Typography>
);

const NumberIcon = () => (
  <Typography variant="body1" className="grb-text-icon">01</Typography>
);

const SymbolIcon = () => (
  <Typography variant="body1" className="grb-text-icon">!@</Typography>
);

const CacheIcon = React.memo(() => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={{ width: '24px', height: '24px' }}>
    <path d="M60,5c-10.89,0-20.47,2.44-26.84,6.33C16.92,12.93,5,20.12,5,29v48c0,10.09,15.37,18,35,18c10.89,0,20.47-2.44,26.85-6.33
      C83.08,87.07,95,79.89,95,71V23C95,12.91,79.63,5,60,5z M71,77c0,7.59-14.2,14-31,14S9,84.59,9,77v-7.52C14.81,75.19,26.37,79,40,79
      s25.19-3.81,31-9.52V77z M71,61c0,7.59-14.2,14-31,14S9,68.59,9,61v-7.52C14.81,59.19,26.37,63,40,63s25.19-3.81,31-9.52V61z M71,45
      c0,7.59-14.2,14-31,14S9,52.59,9,45v-7.52C14.81,43.19,26.37,47,40,47s25.19-3.81,31-9.52V45z M40,43C23.2,43,9,36.59,9,29
      c0-7.59,14.2-14,31-14s31,6.41,31,14C71,36.59,56.8,43,40,43z M91,71c0,5.59-7.7,10.53-18.47,12.76C74.12,81.68,75,79.4,75,77v-5.68
      c6.89-1.64,12.5-4.39,16-7.84V71z M91,55c0,5.15-6.54,9.76-16,12.19V55.32c6.89-1.64,12.5-4.39,16-7.84V55z M91,39
      c0,5.15-6.54,9.76-16,12.19V39.32c6.89-1.64,12.5-4.39,16-7.84V39z M75,35.19V29c0-9.41-13.38-16.92-31.1-17.89
      C48.63,9.78,54.15,9,60,9c16.8,0,31,6.41,31,14C91,28.15,84.46,32.76,75,35.19z"
    />
  </svg>
), () => true);

export default (options = {}) => {
  return SettingsPage({
    ...options,
    settingsEntity: SETTINGS_ENTITY,
    iconsByName: {
      Authentication: AuthIcon,
      Connection: ConnectionIcon,
      Filter: FilterIcon,
      Password: PasswordIcon,
      Width: WidthIcon,
      Case: CaseIcon,
      Digits: NumberIcon,
      Symbols: SymbolIcon,
      Lock: LockIcon,
      Sync: SyncIcon,
      Cache: CacheIcon,
      Schedule: ScheduleIcon,
    },
    pages: {
      passwordPolicy: {
        getFormData: settings => settings.passwordPolicy,
        setFormData: (settings, formData) => {
          settings.passwordPolicy = formData;
          return settings;
        },
        properties: {
          enableAccountLock: {
            getValue: data => (data.maxFailedLogins !== 0),
            setValue: (data, value) => { data.maxFailedLogins = value ? 5 : 0; },
          },
          maxFailedLogins: {
            props: (data) => {
              return {
                displayed: !!data.maxFailedLogins,
              };
            },
          },
        },
      },
      authentication: {
        getFormData: settings => settings.providerSettings,
        setFormData: (settings, formData) => { settings.providerSettings = formData; return settings; },
        getPages: (settings) => {
          const { provider = 1 } = settings.providerSettings || {};
          return provider === 2 ? ['ldap', 'syncSettings'] : [];
        },
      },
      ldap: {
        getFormData: (settings, { defaultValue }) => {
          return settings.providerSettings
              && settings.providerSettings[LDAP_SETTINGS]
              || { ...defaultValue };
        },
        setFormData: (settings, formData) => {
          const newData = { ...settings };
          if (!newData.providerSettings) {
            newData.providerSettings = {};
          }
          newData.providerSettings[LDAP_SETTINGS] = formData;
          return newData;
        },
        properties: {
          tlsInsecure: {
            props: (data) => {
              return {
                disabled: !data.secure,
              };
            },
          },
          tlsCerts: {
            props: formData => ({
              disabled: !formData.secure,
            }),
          },
        },
        editors: {
          'long-string': LongTextFormEditor,
        },
        types: {
          testButton: ({ config, key }) => ({
            render: props => <TestButton {...props} key={key} config={config} />,
          }),
        },
      },
      syncSettings: {
        getFormData: (settings, { defaultValue }) => {
          return settings.providerSettings
              && settings.providerSettings.syncSettings
              || { ...defaultValue };
        },
        setFormData: (settings, formData) => {
          const newData = { ...settings };
          if (!newData.providerSettings) {
            newData.providerSettings = {};
          }
          newData.providerSettings.syncSettings = formData;
          return newData;
        },
        properties: {
        },
      },
      systemAuth: {
        getFormData: (settings, { defaultValue }) => {
          return settings.systemSettings
              || { ...defaultValue };
        },
        setFormData: (settings, formData) => {
          return { ...settings, systemSettings: formData };
        },
        properties: {
        },
      },
      systemDataManagement: {
        getFormData: (settings, { defaultValue }) => {
          return settings.systemSettings
              || { ...defaultValue };
        },
        setFormData: (settings, formData) => {
          return { ...settings, systemSettings: formData };
        },
        properties: {
        },
      },
    },
  });
};
