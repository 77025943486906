import {
  STRING_VALUE_TYPE,
  CELL_LAYOUT_TYPE,
  ARRAY_VALUE_TYPE,
  LABELLED_LAYOUT_TYPE,
} from 'grb-webui/components/FilterBar';

export default ({ authService }) => {
  const roles = authService.getRoles();
  const ROLES_COMBO_ENUM = [
    { value: undefined, label: 'All roles' },
    ...roles.map(r => ({ value: r, label: r })),
  ];
  return {
    emptyChipLabel: 'All users',
    dialogClassName: 'grb-container-dialog-filter',
    getId: t => t.id,
    searchKey: 'search',
    propertyEditors: [
      {
        title: 'Username',
        name: 'username',
        type: STRING_VALUE_TYPE,
        layout: CELL_LAYOUT_TYPE,
        getValueLabel(value) {
          return value === undefined ? 'All users' : `Username ${value}`;
        },
      },
      {
        title: 'Email',
        name: 'email',
        type: STRING_VALUE_TYPE,
        layout: CELL_LAYOUT_TYPE,
        getValueLabel(value) {
          return value === undefined ? 'All users' : `Email ${value}`;
        },
      },
      {
        title: 'Role',
        name: 'role',
        type: ARRAY_VALUE_TYPE,
        layout: LABELLED_LAYOUT_TYPE,
        namedValues: ROLES_COMBO_ENUM,
        createObjectFilter: (v) => {
          if (!v || !v.length) {
            return () => true;
          }
          const acceptedRoles = roles.map(r => (v.indexOf(r) >= 0));
          return o => acceptedRoles[o.role];
        },
      },
    ].filter(e => e),
  };
};
