// import { ExecuteAll } from 'grb-webui/services/Promise';
import DefaultJobService from 'grb-admin-console/services/JobService';

class JobService extends DefaultJobService {
  requestJobMetrics(job, params) {
    // return this.apiClient.get(`/machines/${job.nodeId}/jobs/${job.id}/metrics`, {
    return this.apiClient.get(`/jobs/${job.id}/metrics`, {
      params,
      // headers: {
      //   accept: 'Content-Type: text/html; charset=UTF-8',
      //   'X-GUROBI-SERVER': job.address,
      //   'X-GUROBI-ROUTER': job.router,
      //   'X-GUROBI-CSPASSWORD': job.userPassword,
      // },
    });
  }
}

export default JobService;
