import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import APIKeyIcon from '@material-ui/icons/Code';
import PersonIcon from '@material-ui/icons/Person';
import { extendViewService } from 'tuc-webui-base/services/ViewService';
import SearchBar from 'tuc-webui-base/components/SearchBar';
import { useRowNumber, usePendingSelection } from 'tuc-webui-base/components/TableViewComponent';
import ConfirmDialog from 'tuc-webui-base/components/ConfirmDialog';
import { FormatBasedEditor } from 'tuc-webui-base/components/editors';
import MessageDialog from 'grb-webui/components/MessageDialog';
import FilterBar, { withPageFilter } from 'grb-webui/components/FilterBar';
import { ActionButtons, useMenu } from 'grb-webui/components/Actions';
import { TABLE_PANEL_TYPE } from 'grb-webui/ComponentTypes';
import { withStoreData } from 'grb-webui/store';
import { StateEditor } from 'grb-webui/editors';
import AppMainPage, { FIT_TO_PAGE_VARIANT } from 'grb-admin-console/components/AppMainPage';
import UserActions from './UserActions';
import EditUserDialogHOC from './EditUserDialog';
import UserFilter from './UserFilter';
import { USERS_ENTITY } from '../../Entities';
import './UsersContainer.scss';

const AllAccessIcon = React.memo(() => (
  <div className="grb-all-access-icon">
    <PersonIcon />
    <APIKeyIcon className="grb-api-key-icon" />
  </div>
), () => true);

const API_KEY_DISABLED = 'login';
const API_KEY_DISABLED_USER_DISABLED = 'login_disabled';
const TOKENS_DISABLED = 'apiKeys';
const TOKENS_DISABLED_USER_DISABLED = 'apiKeys_disabled';
const ALL_ACCESS = 'loginapiKeys';
const ALL_ACCESS_USER_DISABLED = 'loginapiKeys_disabled';
const makeAccessStates = ({ name, nameDis, icon, text, className }) => ([
  { value: name, icon, text, className: `grb-user-icon grb-user-icon-enabled ${className}` },
  { value: nameDis, icon, text, className: `grb-user-icon grb-user-icon-disabled ${className}` },
]);

const UsersContainer = (pageMode) => {
  const { authService, userService, noFilter } = pageMode;
  const roles = authService.getRoles();

  const actions = pageMode.actions ? { ...UserActions(pageMode), ...pageMode.actions } : UserActions(pageMode);
  const {
    rowButtons = ['edit', 'menu'],
    rowMenuItems = ['changePassword', 'rename', 'disable', 'delete'],
  } = pageMode;
  const actionOptions = {};

  const ViewServiceProperties = {
    componentsByName: {
      UserRowButtons: ActionButtons(actions, rowButtons, actionOptions),
    },
    editors: [
      new StateEditor('userAuthAccess', {
        getStateValue: (v, u) => {
          if (u.apiKeysDisabled) {
            return u.disabled ? API_KEY_DISABLED_USER_DISABLED : API_KEY_DISABLED;
          }
          if (u.tokenDisabled) {
            return u.disabled ? TOKENS_DISABLED_USER_DISABLED : TOKENS_DISABLED;
          }
          return u.disabled ? ALL_ACCESS_USER_DISABLED : ALL_ACCESS;
        },
        getTooltip: (state, u) => {
          if (u.disabled) {
            return u.disabledReason || 'User disabled';
          }
          if (u.apiKeysDisabled) {
            return 'Interactive login enabled';
          }
          return u.tokenDisabled ? 'API keys enabled' : 'All authentication access enabled';
        },
        states: [
          ...makeAccessStates({
            name: ALL_ACCESS,
            nameDis: ALL_ACCESS_USER_DISABLED,
            text: 'All authentication access enabled',
            icon: AllAccessIcon,
            className: 'grb-user-icon-auth-all' }),
          ...makeAccessStates({
            name: API_KEY_DISABLED,
            nameDis: API_KEY_DISABLED_USER_DISABLED,
            text: 'Interactive login enabled',
            icon: PersonIcon,
            className: 'grb-user-icon-auth-login' }),
          ...makeAccessStates({
            name: TOKENS_DISABLED,
            nameDis: TOKENS_DISABLED_USER_DISABLED,
            text: 'API Keys enabled',
            icon: APIKeyIcon,
            className: 'grb-user-icon-auth-api' }),
        ],
        checkedOn: true,
      }),
      new FormatBasedEditor('userRole', (role) => {
        return roles[role || 0];
      }, {
        sortValue: value => (value === undefined ? 0 : value),
      }),
    ],
  };

  const EditUserDialog = EditUserDialogHOC(pageMode);

  //
  // The Container returned with the HOC
  const Container = (props) => {
    const {
      users,
      viewService,
      filter,
      arrayFilter,
      onFilterChange,
      filterEditor,
      onSnackbarMessage,
      search,
      onSearchChange,
      configuration,
      ...rest
    } = props;

    const usersCount = useRowNumber(users, false, pageMode.serverFiltering);
    const [TablePanel] = useState(() => viewService.getComponent(TABLE_PANEL_TYPE));
    const [extViewService] = useState(() => extendViewService(viewService, ViewServiceProperties));
    const [dialogMessage, setDialogMessage] = useState({ open: false });
    const [confirmDialog, setConfirmDialog] = useState({ open: false });
    const [editUserDialog, setEditUserDialog] = useState({ open: false });
    const { menuProps, onOpenMenu, Menu } = useMenu(actions, rowMenuItems, actionOptions);
    const { selected, setPendingSelectionId, onSelect } = usePendingSelection(null, users);

    //
    // Delete user
    //
    const handlePromptDeleteUser = useCallback((user) => {
      const handleDeleteUser = (u) => {
        setConfirmDialog({ open: false });
        if (u) {
          userService.deleteUser(u)
          .then(() => {
            onSnackbarMessage({ message: 'User deleted' });
          })
          .catch(e => viewService.onError(e));
        }
      };

      const msg = userService.validateDeleteUser(user);
      if (msg) {
        setDialogMessage({ title: 'Delete user error', message: msg, open: true });
      } else {
        setConfirmDialog({
          open: true,
          title: 'Confirm Delete',
          message: 'Are you sure you want to delete this user?',
          buttons: [{ title: 'Cancel', value: null }, { title: 'Delete user', value: user }],
          onClose: handleDeleteUser,
        });
      }
    }, []);

    //
    // Disable User
    //
    const handleDisableUser = useCallback((user) => {
      const disableUser = (u) => {
        setConfirmDialog({ open: false });
        if (u) {
          const enable = u.disabled;
          userService.disableUser(u, enable)
          .then(() => {
            onSnackbarMessage({ message: `User ${enable ? 'enabled' : 'disabled'}` });
          })
          .catch(e => viewService.onError(e));
        }
      };

      const msg = userService.validateDisableUser(user);
      if (msg) {
        setDialogMessage({
          title: `${user.disabled ? 'Enable' : 'Disable'} user error`,
          message: msg,
          open: true,
        });
      } else if (!user.disabled) {
        setConfirmDialog({
          open: true,
          title: 'Are you sure you want to disable this user?',
          message: 'This user won\'t get authorized to access this application anymore.',
          buttons: [
            { title: 'Cancel', value: null, id: 'cancel-disable' },
            { title: 'Disable user', value: user, id: 'confirm-disable' },
          ],
          onClose: disableUser,
        });
      } else {
        disableUser(user);
      }
    }, [onSnackbarMessage, userService]);

    //
    // Disable User
    //
    const handleDisableUserAPIKeys = useCallback((user) => {
      const disableUserAPIKeys = (u) => {
        setConfirmDialog({ open: false });
        if (u) {
          const enable = u.apiKeysDisabled;
          userService.disableUserAPIKeys(u, enable)
          .then(() => {
            onSnackbarMessage({ message: `User API keys ${enable ? 'enabled' : 'disabled'}` });
          })
          .catch(e => viewService.onError(e));
        }
      };

      const msg = userService.validateDisableAPIKeysUser(user);
      if (msg) {
        setDialogMessage({
          title: `${user.apiKeysDisabled ? 'Enable' : 'Disable'} user API keys error`,
          message: msg,
          open: true,
        });
      } else if (!user.apiKeysDisabled) {
        setConfirmDialog({
          open: true,
          title: 'Are you sure you want to disable this user API keys?',
          message: 'Existing applications using these user keys won\'t get authorized anymore.',
          buttons: [{ title: 'Cancel', value: null }, { title: 'Disable user API Keys', value: user }],
          onClose: disableUserAPIKeys,
        });
      } else {
        disableUserAPIKeys(user);
      }
    }, [onSnackbarMessage, userService]);

    //
    // Edit User
    //
    const handlePromptEditUser = useCallback((user) => {
      const handleEditUser = (u, params) => {
        setEditUserDialog({ open: false });
        if (params !== undefined) {
          userService.editUser(u, params)
          .then(() => {
            onSnackbarMessage({ message: 'User edited' });
          })
          .catch(e => viewService.onError(e));
        }
      };

      const message = userService.validateEditUser(user);
      if (message) {
        setDialogMessage({ title: 'Edit user error', message, open: true });
      } else {
        setEditUserDialog({
          open: true,
          onClose: handleEditUser,
          user,
          title: `Edit user ${user.username}`,
          nonEditableProps: ['id', 'username', 'password'],
        });
      }
    }, [onSnackbarMessage, userService]);

    //
    // Create User
    //
    const handlePromptCreateUser = useCallback(() => {
      const handleCreateUser = (params) => {
        setEditUserDialog({ open: false });
        if (params !== undefined) {
          userService.createUser(params)
          .then((user) => {
            setPendingSelectionId(user.id);
          })
          .catch(e => viewService.onError(e));
        }
      };

      const msg = userService.validateCreateUser();
      if (msg) {
        setDialogMessage({ title: 'Create user error', message: msg, open: true });
      } else {
        setEditUserDialog({ open: true, onClose: handleCreateUser, title: 'Create User' });
      }
    }, [onSnackbarMessage, userService]);

    //
    // Change user password
    //
    const handlePromptUserPassword = useCallback((user) => {
      const handleEditUser = (u, params) => {
        setEditUserDialog({ open: false });
        if (params !== undefined) {
          userService.changePassword(u, params)
          .then(() => {
            onSnackbarMessage({ message: 'User edited' });
          })
          .catch(e => viewService.onError(e));
        }
      };

      const message = userService.validateEditUser(user);
      if (message) {
        setDialogMessage({ title: 'Edit user error', open: true });
      } else {
        setEditUserDialog({
          open: true,
          onClose: handleEditUser,
          editableProps: ['password'],
          user,
          title: `Change ${user.username} password`,
        });
      }
    }, [onSnackbarMessage, userService]);

    //
    // Rename user
    //
    const handleRenameUser = useCallback((user) => {
      const renameUser = (u, params, mergedWith) => {
        if (params !== undefined) {
          userService.renameUser(u, params)
          .then(() => {
            setPendingSelectionId(mergedWith && mergedWith.id || u.id);
            onSnackbarMessage({ message: 'User edited' });
          })
          .catch(e => viewService.onError(e));
        }
      };

      const handleEditUserClose = (u, params) => {
        setEditUserDialog({ open: false });
        if (params !== undefined) {
          const mergeWith = users && users.find(x => (x.username === params.username));
          if (mergeWith) {
            const handleCloseConfirm = (pps) => {
              setConfirmDialog({ open: false });
              if (pps) {
                renameUser(u, pps, mergeWith);
              }
            };

            setConfirmDialog({
              open: true,
              title: 'Confirm Merge of Users',
              message: `A user with the username ${params.username} already exists.\nDo you want to merge the user ${user.username} into the user ${params.username}?`,
              buttons: [
                { title: 'Cancel', value: null, id: 'cancel-merge-users' },
                { title: 'Merge users', value: { newUsername: params.username, merge: true }, id: 'confirm-merge-users' },
              ],
              onClose: handleCloseConfirm,
            });
          } else {
            renameUser(u, { newUsername: params.username });
          }
        }
      };

      const message = userService.validateRenameUser(user);
      if (message) {
        setDialogMessage({ title: 'Rename user error', message, open: true });
      } else {
        setEditUserDialog({
          open: true,
          onClose: handleEditUserClose,
          editableProps: ['username'],
          user,
          title: `Rename user ${user.username}`,
        });
      }
    }, [onSnackbarMessage, userService, users]);

    // Close message dialog
    const handleCloseMessageDialog = () => {
      setDialogMessage({ open: false });
    };

    //
    // Header
    //
    const HeaderBar = (
      <React.Fragment>
        {!noFilter && (
          <FilterBar
            {...rest}
            filter={filter || {}}
            filterEditor={filterEditor}
            onFilterChange={onFilterChange}
          />
        )}
        <SearchBar
          {...props}
          placeholder="Search users table..."
          value={search}
          onChange={onSearchChange}
        />
        <div className="grb-main-page-header-buttons">
          <Button
            variant="contained"
            color="primary"
            aria-label="add"
            onClick={handlePromptCreateUser}
            id="create-user"
          >
            Create User
          </Button>
        </div>
      </React.Fragment>
    );

    const filteredUsers = arrayFilter ? arrayFilter(users) : users;
    const callbacks = {
      onDeleteUser: handlePromptDeleteUser,
      onEditUser: handlePromptEditUser,
      onDisableUser: handleDisableUser,
      onDisableUserAPIKeys: handleDisableUserAPIKeys,
      onChangePassword: handlePromptUserPassword,
      onRenameUser: handleRenameUser,
    };

    return (
      <AppMainPage
        route={props.route}
        variant={FIT_TO_PAGE_VARIANT}
        TrailingBar={HeaderBar}
        className="grb-users-page"
        rowCount={usersCount}
        help={configuration && configuration.help}
      >
        <div className={classnames('grb-users-ctnr', 'grb-fullpage', 'MuiPaper-elevation4')}>
          <TablePanel
            {...rest}
            {...rest.component}
            {...callbacks}
            users={filteredUsers}
            viewService={extViewService}
            usersCount={usersCount}
            selected={selected}
            onSelect={onSelect}
            onOpenMenu={onOpenMenu}
          />
          {dialogMessage.open && (
            <MessageDialog
              title={dialogMessage.title}
              message={dialogMessage.message}
              onClose={handleCloseMessageDialog}
              open
            />
          )}
          {confirmDialog.open && <ConfirmDialog {...confirmDialog} maxWidth="md" />}
          {editUserDialog.open && (
            <EditUserDialog
              {...rest}
              {...editUserDialog}
              viewService={viewService}
              userService={userService}
              users={users}
            />
          )}
          <Menu
            {...props}
            {...menuProps}
            {...callbacks}
            id="user-menu"
          />
        </div>
      </AppMainPage>
    );
  };

  Container.propTypes = {
    viewService: PropTypes.shape({
      getComponent: PropTypes.func.isRequired,
      onError: PropTypes.func.isRequired,
    }).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    filter: PropTypes.shape({
      overview: PropTypes.string,
    }),
    arrayFilter: PropTypes.func,
    onFilterChange: PropTypes.func,
    filterEditor: PropTypes.shape({}),
    route: PropTypes.shape({}).isRequired,
    onSnackbarMessage: PropTypes.func.isRequired,
    search: PropTypes.string,
    onSearchChange: PropTypes.func,
    configuration: PropTypes.shape({
      help: PropTypes.shape({}),
    }),
  };

  Container.defaultProps = {
    filter: undefined,
    arrayFilter: undefined,
    onFilterChange: undefined,
    filterEditor: undefined,
    search: undefined,
    onSearchChange: undefined,
    configuration: undefined,
  };

  return withPageFilter(
    withStoreData(Container, { entities: [USERS_ENTITY], onUpdateStore: 'onUpdateUsers' }),
    UserFilter(pageMode),
  );
};

export default UsersContainer;
