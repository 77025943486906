import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { MAIN_JOBS } from 'grb-admin-console/services/JobService';
import './JobsLink.scss';

const JobsLink = ({ value, object: node, history, configuration, jobService }) => {
  const { jobStatuses, link } = configuration || {};
  const onClick = () => {
    jobService.filterRecentJobsOnNode(node.address, jobStatuses);
    history.push(link);
  };
  // if (!value) {
  //   return <span>value</span>;
  // }
  return (
    <Button component={Link} color="primary" onClick={onClick} className="grbrsm-jobslink-button">
      {value}
    </Button>
  );
};

JobsLink.propTypes = {
  value: PropTypes.number,
  object: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  configuration: PropTypes.shape({}),
  jobService: PropTypes.shape({
    filterRecentJobsOnNode: PropTypes.func.isRequired,
  }).isRequired,
};

JobsLink.defaultProps = {
  value: 0,
  configuration: { jobType: MAIN_JOBS, link: '/jobs/main' },
};

export default JobsLink;
