import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import ProfileIcon from '@material-ui/icons/Person';
import AppMainPage from 'grb-admin-console/components/AppMainPage';
import ChangePasswordDialog from '../../components/ChangePasswordDialog';
import PasswordIcon from '../../components/PasswordIcon';

import './EditProfileContainer.scss';

const FIRSTNAME_PROPERTY = 'firstname';
const LASTNAME_PROPERTY = 'lastname';
const EMAIL_PROPERTY = 'email';

const PROFILE_PROPERTIES = [
  FIRSTNAME_PROPERTY,
  LASTNAME_PROPERTY,
  EMAIL_PROPERTY,
];

const HELP_CONFIG = {
  url: '/doc/usermenu#profile',
  target: '_blanck',
};

class EditProfileContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.handleSaveProfile = this.handleSaveProfile.bind(this);
    this.handleClosePasswordDialog = this.handleClosePasswordDialog.bind(this);
    this.handleOpenPasswordDialog = this.handleOpenPasswordDialog.bind(this);
  }

  getInitialState() {
    const { profile, authService } = this.props;
    const message = authService.validateEditProfile(profile);
    const state = {
      processing: false,
      error: undefined,
      validationErrors: {},
      passwordDlgOpen: false,
      role: authService.getRole(profile),
      message,
      canEditProfile: !message,
    };
    PROFILE_PROPERTIES.forEach((propName) => {
      state[propName] = profile[propName] || '';
      state.validationErrors[propName] = this.validateValue(state[propName], propName);
    });
    return state;
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile && !prevProps.profile) {
      this.setState(this.getInitialState());
    }
  }

  handleChange = name => (e) => {
    const { value } = e.target;
    this.setState(prevState => ({
      [name]: value,
      validationErrors: Object.assign({}, prevState.validationErrors,
        { [name]: this.validateValue(value, name) }),
      error: undefined,
    }));
  };

  handleClosePasswordDialog(newPassword) {
    if (newPassword) {
      this.setState({
        passwordDlgOpen: false,
      });
    } else {
      this.setState({ passwordDlgOpen: false });
    }
  }

  handleOpenPasswordDialog() {
    this.setState({ passwordDlgOpen: true });
  }

  handleSaveProfile() {
    const { authService, onProfileChanged } = this.props;
    const newProfile = {};
    PROFILE_PROPERTIES.forEach((propName) => {
      newProfile[propName] = this.state[propName];
    });
    authService.updateProfile(newProfile).then(() => {
      if (onProfileChanged) {
        onProfileChanged(newProfile);
      }
    }).catch((err) => {
      let msg;
      if (!err.response || !err.response.data || !err.response.data.error) {
        msg = `${err}`;
      } else {
        const { error } = err.response.data;
        msg = `[${error.code}] ${error.message}`;
      }
      this.setState({ error: msg, processing: false });
    });
  }

  validateValue(value, propertyName) {
    if ((propertyName === FIRSTNAME_PROPERTY) || (propertyName === LASTNAME_PROPERTY)) {
      if (!value) {
        return `${propertyName === FIRSTNAME_PROPERTY ? 'Firstname' : 'Lastname'} must not be empty`;
      }
    } else if (propertyName === EMAIL_PROPERTY) {
      if (value) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase()) ? null : 'Invalid email address';
      }
    }
    return undefined;
  }

  hasErrors() {
    return !!Object.keys(this.state.validationErrors).find(key => this.state.validationErrors[key]);
  }

  hasChanges() {
    const { profile } = this.props;
    const changed = PROFILE_PROPERTIES.find((propName) => {
      if (!this.state[propName]) return profile[propName];
      return !profile[propName] || (this.state[propName] !== profile[propName]);
    });
    return changed;
  }

  render() {
    const { processing, error, role, message, canEditProfile } = this.state;

    const HeaderBar = (
      <React.Fragment>
        <div className="grb-main-page-header-buttons">
          {canEditProfile && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleOpenPasswordDialog}
              id="grb-change-password-button"
            >
              <PasswordIcon />Change Password
            </Button>
          )}
          {canEditProfile && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSaveProfile}
              disabled={this.hasErrors() || !this.hasChanges()}
              id="grb-save-profile-button"
            >
              Save Profile
            </Button>
          )}
        </div>
      </React.Fragment>
    );

    return (
      <AppMainPage
        {...this.props}
        Icon={ProfileIcon}
        pageTitle={`${this.props.authService.getUsername()} profile`}
        TrailingBar={HeaderBar}
        className="grbrsm-user-profile-page"
        backTo
        help={HELP_CONFIG}
      >
        <Paper className="grbrsm-edit-profile grbrsm-form-section-body" elevation={4}>
          {error && (
            <div className="grb-error">
              <Typography variant="body1">
                {error}
              </Typography>
            </div>
          )}
          {!error && message && (
            <div className="grb-message">
              <Typography variant="body1">
                {message}
              </Typography>
            </div>
          )}
          <FormControl
            margin="normal"
            fullWidth
            error={this.state.validationErrors.firstname}
          >
            <InputLabel htmlFor="firstname">Firstname</InputLabel>
            <Input
              id="firstname"
              name="firstname"
              error={Boolean(this.state.validationErrors.firstname)}
              value={this.state.firstname}
              onChange={this.handleChange('firstname')}
              disabled={processing || !canEditProfile}
            />
            {this.state.validationErrors.firstname && (
              <FormHelperText>{this.state.validationErrors.firstname}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            error={this.state.validationErrors.lastname}
          >
            <InputLabel htmlFor="lastname">Lastname</InputLabel>
            <Input
              id="lastname"
              name="lastname"
              error={Boolean(this.state.validationErrors.lastname)}
              value={this.state.lastname}
              onChange={this.handleChange('lastname')}
              disabled={processing || !canEditProfile}
            />
            {this.state.validationErrors.lastname && (
              <FormHelperText>{this.state.validationErrors.lastname || message}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            error={this.state.validationErrors.email}
          >
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              id="email"
              name="email"
              error={Boolean(this.state.validationErrors.email)}
              value={this.state.email}
              onChange={this.handleChange('email')}
              disabled={processing || !canEditProfile}
            />
            {this.state.validationErrors.email && (
              <FormHelperText>{this.state.validationErrors.email}</FormHelperText>
            )}
          </FormControl>
          <div className="tuc-textfield-editor grbrsm-readonly">
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="role">Role</InputLabel>
              <Input
                id="role"
                name="role"
                value={role}
                disabled
                readOnly
              />
              <FormHelperText>Role is defined by the system administrator</FormHelperText>
            </FormControl>
          </div>
        </Paper>
        <ChangePasswordDialog
          {...this.props}
          open={this.state.passwordDlgOpen}
          onClose={this.handleClosePasswordDialog}
        />
      </AppMainPage>
    );
  }
}

EditProfileContainer.propTypes = {
  profile: PropTypes.shape({}),
  onProfileChanged: PropTypes.func,
  authService: PropTypes.shape({
    getRole: PropTypes.func,
    updateProfile: PropTypes.func,
    getUsername: PropTypes.func,
    validateEditProfile: PropTypes.func,
  }).isRequired,
  route: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}),
};

EditProfileContainer.defaultProps = {
  profile: undefined,
  onProfileChanged: undefined,
  location: undefined,
};

export default EditProfileContainer;
