import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchBar from 'tuc-webui-base/components/SearchBar';
import { extendViewService } from 'tuc-webui-base/services/ViewService';
import { useRowNumber } from 'tuc-webui-base/components/TableViewComponent';
import FilterBar, { withPageFilter } from 'grb-webui/components/FilterBar';
import { TABLE_PANEL_TYPE } from 'grb-webui/ComponentTypes';
import { withStoreData } from 'grb-webui/store';
import AppMainPage, { FIT_TO_PAGE_VARIANT } from 'grb-admin-console/components/AppMainPage';
import {
  LICENSES_ENTITY,
} from '../../Entities';
import LicenseStatus from './LicenseStatus';
import './LicensesContainer.scss';

const getLicenseId = l => l.nodeId;

export default (options = {}) => {
  const LicenseFilter = {
    searchKey: 'search',
    propertyEditors: [],
  };

  const ViewServiceProperties = {
    componentsByName: {
      LicenseStatus,
    },
    editors: [],
  };

  const LicensesContainer = (props) => {
    const {
      filter,
      arrayFilter,
      onFilterChange,
      filterEditor,
      onUpdateNodes,
      licenses,
      search,
      onSearchChange,
      configuration,
      ...rest
    } = props;
    const {
      viewService,
    } = props;
    const [TablePanel] = useState(() => viewService.getComponent(TABLE_PANEL_TYPE));
    const licensesCount = useRowNumber(licenses, false, options.serverFiltering);
    const [extViewService] = useState(() => extendViewService(viewService, ViewServiceProperties));

    const filteredLicenses = arrayFilter ? arrayFilter(licenses) : licenses;
    //
    // Header
    //
    const HeaderBar = (
      <React.Fragment>
        {(options.filter !== false) && (
          <FilterBar
            {...rest}
            filter={filter || {}}
            filterEditor={filterEditor}
            onFilterChange={onFilterChange}
          />
        )}
        <SearchBar
          {...props}
          placeholder="Search licenses table..."
          value={search}
          onChange={onSearchChange}
        />
      </React.Fragment>
    );

    return (
      <AppMainPage
        route={props.route}
        variant={FIT_TO_PAGE_VARIANT}
        TrailingBar={HeaderBar}
        className="grb-licenses-page"
        rowCount={licensesCount}
        help={configuration && configuration.help}
      >
        <div className={classnames('grbrsm-license-table-panel', 'grb-fullpage', 'MuiPaper-elevation4')}>
          <TablePanel
            {...rest}
            {...rest.component}
            licenses={filteredLicenses}
            viewService={extViewService}
            licensesCount={licensesCount}
            getObjectId={getLicenseId}
          />
        </div>
      </AppMainPage>
    );
  };

  LicensesContainer.propTypes = {
    licenses: PropTypes.arrayOf(PropTypes.shape({})),
    viewService: PropTypes.shape({
      onError: PropTypes.func,
      getComponent: PropTypes.func,
    }).isRequired,
    route: PropTypes.shape({}).isRequired,
    component: PropTypes.shape({}).isRequired,
    arrayFilter: PropTypes.func,
    onFilterChange: PropTypes.func,
    filter: PropTypes.shape({}),
    filterEditor: PropTypes.shape({}),
    onSnackbarMessage: PropTypes.func.isRequired,
    onUpdateNodes: PropTypes.func,
    search: PropTypes.string,
    onSearchChange: PropTypes.func,
    configuration: PropTypes.shape({
      help: PropTypes.shape({}),
    }),
  };

  LicensesContainer.defaultProps = {
    licenses: undefined,
    filter: undefined,
    arrayFilter: undefined,
    onFilterChange: undefined,
    filterEditor: undefined,
    onUpdateNodes: undefined,
    search: undefined,
    onSearchChange: undefined,
    configuration: undefined,
  };

  return withPageFilter(
    withStoreData(LicensesContainer, {
      entities: [{ name: LICENSES_ENTITY }],
      onUpdateStore: 'onUpdateNodes',
    }),
    LicenseFilter,
  );
};
