import { NODES_ENTITY, LICENSES_ENTITY } from '../Entities';

const NODE_TYPENAME = 'Node';
const LICENSE_TYPENAME = 'License';

const ACTION_START_NODE = 'startNode';
const ACTION_STOP_NODE = 'stopNode';
const ACTION_EDIT_NODE = 'editNode';

const NODE_PROCESSING_STATE_STOPPED = 3;
const NODE_PROCESSING_STATE_DRAINING = 2;

const NODE_STATUS_ALIVE = 1;

class NodeService {
  constructor(config, store, jobService, jsonConfig) {
    this.apiClient = config.apiClient;
    this.authService = config.authService;
    const { schemaService } = config;
    this.jobService = jobService;
    this.store = store;
    this.uri = jsonConfig && jsonConfig.uri || '/nodes';
    this.licensesUri = jsonConfig && jsonConfig.licenseUri || '/licenses';
    const defaultPolling = jsonConfig && jsonConfig.polling && jsonConfig.polling.defaultDelay;
    const runningPolling = jsonConfig && jsonConfig.polling && jsonConfig.polling.runningDelay;

    schemaService.addComputedProperty({
      name: 'nbRunningJobs',
      typename: NODE_TYPENAME,
      label: '#Running jobs',
      type: 'number',
      compute: (node) => {
        return node.metrics && node.metrics.nbRunningJobs || 0;
      },
    });

    schemaService.addComputedProperty({
      name: 'nbQueuedJobs',
      typename: NODE_TYPENAME,
      label: '#Queued jobs',
      type: 'number',
      compute: (node) => {
        return node.metrics && node.metrics.nbQueuedJobs || 0;
      },
    });

    // Register the nodes entity to the store
    store.addEntity(NODES_ENTITY, {
      request: () => (this.apiClient.get(`${this.uri}`)),
      polling: defaultPolling,
      getPolling: (nodes) => {
        const nodeWithRunningJobs = nodes.find(node => node.metrics && (node.metrics.nbRunningJobs || node.metrics.nbQueuedJobs));
        return nodeWithRunningJobs && runningPolling || defaultPolling;
      },
      type: schemaService.getType(NODE_TYPENAME),
      defaultValue: [],
      computeDiffs: true,
    });
    this.startingNodes = {};
    this.stoppingNodes = {};
    this.cancelledPromises = [];

    // Register the licenses entity to the store
    store.addEntity(LICENSES_ENTITY, {
      request: () => (this.apiClient.get(`${this.licensesUri}`)),
      type: schemaService.getType(LICENSE_TYPENAME),
      defaultValue: [],
    });
  }

  getNodeName(node) {
    return node.id;
  }

  canStartNode(node) {
    return (node.processing === NODE_PROCESSING_STATE_DRAINING)
          || (node.processing === NODE_PROCESSING_STATE_STOPPED);
  }

  isStartNodeAllowed() {
    const r = this.authService.canPerformAction(ACTION_START_NODE);
    return (r === true) || (r === null);
  }

  isNodeAlive(node) {
    return (node.status !== undefined) && (node.status === NODE_STATUS_ALIVE);
  }

  validateStartNode(node) {
    if (this.startingNodes[node.id]) {
      return `Node ${this.getNodeName(node)} is already starting`;
    }
    return this.authService.canPerformAction(ACTION_START_NODE, node);
  }

  startNode(node) {
    this.startingNodes[node.id] = true;
    return this.apiClient.post(`/nodes/${node.id}/processing`)
      .finally(() => {
        delete this.startingNodes[node.id];
        this.store.update(NODES_ENTITY);
      });
  }

  validateStopNode(node) {
    if (this.stoppingNodes[node.id]) {
      return `Node ${this.getNodeName(node)} is already stopping`;
    }
    return this.authService.canPerformAction(ACTION_STOP_NODE, node);
  }

  stopNode(node) {
    this.stoppingNodes[node.id] = true;
    return this.apiClient.delete(`/nodes/${node.id}/processing`)
      .finally(() => {
        delete this.stoppingNodes[node.id];
        this.store.update(NODES_ENTITY);
      });
  }

  validateEditNode(node) {
    return this.authService.canPerformAction(ACTION_EDIT_NODE, node);
  }

  setNodeConfig(node, config) {
    return this.apiClient.post(`/nodes/${node.id}/config`, config)
      .finally(() => {
        this.store.update(NODES_ENTITY);
      });
  }

  printError(error) {
    // Process error if it contains an error object, generated by the optimization server
    if (!error.response || !error.response.data || !error.response.data.error) {
      return error.message || error;
    }
    const processedError = Object.assign({}, error.response.data.error);
    if (processedError.code) {
      return `[${processedError.code}] ${processedError.message}`;
    }
    return processedError.message;
  }
}

export default NodeService;
