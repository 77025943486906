import JobsIcon from '@material-ui/icons/Settings';
import QueueIcon from '@material-ui/icons/HourglassEmpty';
import HistoryIcon from '@material-ui/icons/History';
import LicenseIcon from '@material-ui/icons/DataUsage';
import NodesIcon from '@material-ui/icons/DeviceHub';
import UsersIcon from '@material-ui/icons/People';
import ProfileIcon from '@material-ui/icons/Person';
import APIKeyIcon from '@material-ui/icons/Code';
import LicensesIcon from '@material-ui/icons/Description';
import AccountIcon from '@material-ui/icons/AccountBox';
import DocumentationIcon from '@material-ui/icons/HelpOutline';
import SubmitBatchIcon from '@material-ui/icons/Input';
import SettingsIcon from '@material-ui/icons/Tune';
import SecurityIcon from '@material-ui/icons/Security';
import SystemIcon from '@material-ui/icons/SettingsApplications';
import OverviewIcon from '@material-ui/icons/Subject';
import UsermanIcon from '@material-ui/icons/ImportContacts';
import NewReleaseIcon from '@material-ui/icons/NewReleases';
import {
  LOGIN_COMPONENT_TYPE,
  EDIT_PROFILE_TYPE,
  APP_TOOLBAR_LEADING_CONTENT_TYPE,
  APP_TOOLBAR_TRAILING_CONTENT_TYPE,
  APP_TOOLBAR_CONTENT_TYPE,
  APP_NAV_DRAWER_HEADER_TYPE,
  HTML_VIEW_TYPE,
} from 'tuc-webui-base/services/ViewService';
import { Plugin } from 'tuc-webui-base/configuration';
import { TOUR_DIALOG_TYPE, SWIPEABLE_TABS_TYPE, GRAPH_DASHBOARD_TYPE } from 'grb-webui/ComponentTypes';
import {
  SUBMIT_BATCH_TYPE,
  BATCHES_TYPE,
  MAIN_JOBS_TYPE,
  QUEUED_JOBS_TYPE,
  HISTORY_JOBS_TYPE,
  JOB_DASHBOARD_TYPE,
} from 'grb-admin-console/ComponentTypes';
import SchemaService from 'grb-webui/services/SchemaService';
import Store from 'grb-webui/store';
import LogoComponent from 'grb-webui/components/LogoComponent';
import SwipeableTabs from 'grb-webui/components/SwipeableTabs';
import DocComponent from 'grb-webui/components/DocComponent';
import AppTitle from 'grb-webui/components/AppTitle';
import UserBar from 'grb-webui/components/UserBar';
import TourDialog from 'grb-webui/components/TourDialog';
import { createTimeRangeEditor } from 'grb-webui/components/FilterBar/DateFilterEditor';
import VirtualizedTable from 'grb-webui/components/VirtualizedTable';
import { GraphDashboardInfo } from 'grb-webui/components/GraphDashboard';
import APIKeysContainer from 'grb-admin-console/pages/keys';
import RepositoryContainer, { RepositoryIcon } from 'grb-admin-console/pages/repository';
import SubmitBatchContainer from 'grb-admin-console/pages/submitbatch';
import RepositoryService from 'grb-admin-console/services/RepositoryService';
import BatchesContainer, { BatchIcon } from 'grb-admin-console/pages/batches';
import BatchService from 'grb-admin-console/services/BatchService';
import PasswordEditor from 'grb-admin-console/components/PasswordEditor';
import FileSizeEditor from 'grb-admin-console/components/FileSizeEditor';
import DownloadLicenseDialog from 'grb-admin-console/components/DownloadLicenseDialog';
import FileSizeFormatter from 'grb-admin-console/services/FileSizeFormatter';
import {
  BATCHES_ENTITY,
  MAIN_JOBS_ENTITY,
  RUNNING_JOBS_ENTITY,
  QUEUED_JOBS_ENTITY,
} from 'grb-admin-console/Entities';
import LoginPage from './pages/login';
import { QueuedJobsPage, MainJobsPage, HistoryJobsPage, JobDashboard } from './pages/jobs';
import NodesContainer, { JobsLink } from './pages/nodes';
import LicensesContainer from './pages/licenses';
import EditProfileContainer from './pages/userprofile';
import UserAPIKeysContainer from './pages/userapikeys';
import UsersContainer from './pages/users';
import SettingsContainer from './pages/settings';
import JobService from './services/JobService';
import DataService from './services/DataService';
import NodeService from './services/NodeService';
import GrbsrmAuthService from './services/GrbrsmAuthService';
import APIKeyService from './services/APIKeyService';
import UserService from './services/UserService';
import SettingsService from './services/SettingsService';
import {
  NODES_TYPE,
  JOBS_LINK_TYPE,
  LICENSES_TYPE,
  USER_API_KEYS_TYPE,
  API_KEYS_TYPE,
  USERS_TYPE,
  REPOSITORY_TYPE,
  SETTINGS_TYPE,
} from './ComponentTypes';
import { NODES_ENTITY, USERS_ENTITY } from './Entities';

export default class GrbrsmPlugin extends Plugin {
  initialize() {
    this.createTimeRangeEditor = (options = {}) => {
      return createTimeRangeEditor({
        ...options,
        keys: ['from', 'to', 'last', 'filter', 'timerange'],
        toFilter: (query, { stringToDate }) => {
          let { from, to } = query;
          if (!from) {
            if (!query.filter) {
              return null;
            }
            from = query.filter;
            to = 'today';
          }
          return {
            timerange: { from: stringToDate(from), to: stringToDate(to) },
          };
        },
        toQuery: (filter, { convertToMillis }) => {
          const { timerange } = filter;
          if (!timerange || !timerange.from) {
            return null;
          }
          const { from, to } = timerange;
          if (typeof from === 'string') {
            return `filter=${from}`;
          }
          return `from=${convertToMillis(from)}&to=${convertToMillis(to)}`;
        },
      });
    };
  }

  createServices(configuration, { schemaService, authService }) {
    const { jsonConfig = {} } = configuration;
    const store = new Store({ schemaService, authService, onError: configuration.onError });
    this.store = store;
    this.apiClient = configuration.apiClient;
    this.authService = authService;
    this.userApiKeyService = new APIKeyService(configuration, store, jsonConfig.services && jsonConfig.services.userApiKeys);
    this.apiKeyService = new APIKeyService(configuration, store, jsonConfig.services && jsonConfig.services.apiKeys);
    this.jobService = new JobService(configuration, store, jsonConfig.services && jsonConfig.services.jobs);
    this.jobService.getJobDashboardURL = job => `${this.jobService.prefix || ''}/jobs/log?id=${job.id}`;
    const batchService = new BatchService(configuration, store, jsonConfig.services && jsonConfig.services.batches);
    const dataService = new DataService(configuration);
    const nodeService = new NodeService(configuration, store, this.jobService, jsonConfig.services && jsonConfig.services.nodes);
    this.userService = new UserService(configuration, store, jsonConfig.services && jsonConfig.services.users);
    const repositoryService = new RepositoryService(configuration, store, jsonConfig.services && jsonConfig.services.storageObjects);
    const settingsService = new SettingsService(configuration, store, jsonConfig.services && jsonConfig.services.settings);
    return {
      dataService,
      nodeService,
      jobService: this.jobService,
      batchService,
      store,
      apiKeyService: this.apiKeyService,
      repositoryService,
      settingsService,
    };
  }

  createSchemaService(configuration) {
    return new SchemaService(configuration);
  }

  createAuthService(configuration, props) {
    this.authService = new GrbsrmAuthService(configuration, props);
    return this.authService;
  }

  registerComponents(viewService) {
    viewService.registerComponent(HTML_VIEW_TYPE, DocComponent);
    viewService.registerComponent(LOGIN_COMPONENT_TYPE, LoginPage({
      authService: this.authService,
    }));
    viewService.registerComponent(APP_TOOLBAR_TRAILING_CONTENT_TYPE, UserBar({
      menu: [
        'email',
        'divider',
        'role',
        'divider',
        'takeTour',
        'divider',
        'profile',
        'keys',
        'divider',
        'logout',
      ],
    }));
    viewService.registerComponent(TOUR_DIALOG_TYPE, TourDialog);
    viewService.registerComponent(SWIPEABLE_TABS_TYPE, SwipeableTabs);
    viewService.registerComponent('VirtualizedTable', VirtualizedTable);
    viewService.registerComponent(APP_NAV_DRAWER_HEADER_TYPE, null);
    viewService.registerComponent(APP_TOOLBAR_LEADING_CONTENT_TYPE, LogoComponent);
    viewService.registerComponent(APP_TOOLBAR_CONTENT_TYPE, AppTitle);
    viewService.registerComponent(NODES_TYPE, NodesContainer({
      filter: false,
    }));
    viewService.registerComponent(LICENSES_TYPE, LicensesContainer({
      filter: false,
    }));

    // Jobs components
    const jobOptions = {
      createTimeRangeEditor: this.createTimeRangeEditor,
      serverFiltering: false,
      jobService: this.jobService,
      authService: this.authService,
      overviewText: 'Log',
      overviewIcon: null,
      getDownloadLogURL: job => this.jobService.getDownloadLogURL(job),
    };
    viewService.registerComponent(MAIN_JOBS_TYPE, MainJobsPage(jobOptions));
    viewService.registerComponent(QUEUED_JOBS_TYPE, QueuedJobsPage(jobOptions));
    viewService.registerComponent(HISTORY_JOBS_TYPE, HistoryJobsPage(jobOptions));
    viewService.registerComponentInfo(JOB_DASHBOARD_TYPE, JobDashboard(jobOptions));
    viewService.registerComponentInfo(GRAPH_DASHBOARD_TYPE, GraphDashboardInfo);

    // Batch components
    viewService.registerComponent(JOBS_LINK_TYPE, JobsLink);
    viewService.registerComponent(BATCHES_TYPE, BatchesContainer({
      authService: this.authService,
      createTimeRangeEditor: this.createTimeRangeEditor,
    }));
    viewService.registerComponent(EDIT_PROFILE_TYPE, EditProfileContainer);
    const keysOptions = {
      useAdminKeys: false,
      useApplicationName: true,
      useLicense: false,
      useDescription: true,
      useDisabled: true,
      noDownload: true,
      getKey: k => k.key,
      getSecret: k => k.secret,
      getOwner: k => k.username,
      isSameOwner: k => (this.authService.getProfile().username === k.username),
      serverFiltering: false,
      admin: false,
      createTimeRangeEditor: this.createTimeRangeEditor,
      rowButtons: ['edit', 'menu'],
      rowMenuItems: ['disable', 'delete'],
    };
    viewService.registerComponent(USER_API_KEYS_TYPE, UserAPIKeysContainer({
      ...keysOptions,
      cards: true,
      cardsConfig: { groupOn: 'none' },
      create: {},
      getApiKeyService: () => this.userApiKeyService,
      authService: this.authService,
      noFilter: true,
      getPageTitle: () => `${this.authService.getUsername()} API Keys`,
    }));
    viewService.registerComponent(API_KEYS_TYPE, APIKeysContainer({
      ...keysOptions,
      cards: false,
      create: {
        users: {
          entity: USERS_ENTITY,
          apiKeysDisabledProp: 'apiKeysDisabled',
        },
      },
      created: {
        dialog: DownloadLicenseDialog,
      },
      getDownloadLicenseURL: key => this.apiKeyService.getDownloadLicenseURL(key),
    }));

    viewService.registerComponent(USERS_TYPE, UsersContainer({
      userService: this.userService,
      authService: this.authService,
    }));
    // Batch components
    viewService.registerComponent(REPOSITORY_TYPE, RepositoryContainer({
      createTimeRangeEditor: this.createTimeRangeEditor,
    }));
    viewService.registerComponent(SUBMIT_BATCH_TYPE, SubmitBatchContainer({}));
    viewService.registerComponent(SETTINGS_TYPE, SettingsContainer({
      admin: true,
    }));
  }

  registerFormatters(viewService) {
    const ExpirationFormatter = (value) => {
      if (value === 99999999) {
        return 'N/A';
      }
      return value;
    };
    viewService.registerFormatter('expiration', ExpirationFormatter);
    viewService.registerFormatter('fileSize', FileSizeFormatter);
  }

  registerValidators(viewService) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    viewService.registerValidator('email', (value) => {
      return re.test(String(value).toLowerCase()) ? null : 'Invalid email address';
    });
  }

  registerEditors(viewService) {
    viewService.registerEditor('password', new PasswordEditor('password', null, [
      { expression: '.{1,}', message: 'Passwords cannot be empty' },
      { expression: '.{8,}', message: 'Passwords must be at least 8 character long' },
    ]));
    viewService.registerEditor('fileSize', new FileSizeEditor());
  }

  registerIcons(viewService) {
    viewService.registerIcon('Jobs', JobsIcon);
    viewService.registerIcon('Queue', QueueIcon);
    viewService.registerIcon('History', HistoryIcon);
    viewService.registerIcon('License', LicenseIcon);
    viewService.registerIcon('Licenses', LicensesIcon);
    viewService.registerIcon('Account', AccountIcon);
    viewService.registerIcon('APIKey', APIKeyIcon);
    viewService.registerIcon('Documentation', DocumentationIcon);
    viewService.registerIcon('Overview', OverviewIcon);
    viewService.registerIcon('Userman', UsermanIcon);
    viewService.registerIcon('Nodes', NodesIcon);
    viewService.registerIcon('Users', UsersIcon);
    viewService.registerIcon('Profile', ProfileIcon);
    viewService.registerIcon('Repository', RepositoryIcon);
    viewService.registerIcon('SubmitBatch', SubmitBatchIcon);
    viewService.registerIcon('Batches', BatchIcon);
    viewService.registerIcon('MenuBatches', BatchIcon);
    viewService.registerIcon('Settings', SettingsIcon);
    viewService.registerIcon('Security', SecurityIcon);
    viewService.registerIcon('System', SystemIcon);
    viewService.registerIcon('NewRelease', NewReleaseIcon);
  }

  initializeRoutes(routeService, { batchService, repositoryService }) {
    // Job badges
    const jobsRoute = routeService.findRouteByIds(['jobs']);
    const queuedJobsRoute = routeService.findRouteByIds(['queue'], jobsRoute);
    queuedJobsRoute.context.filter.aggregateQueue = window.localStorage.getItem('aggregate-queue') !== 'false';
    queuedJobsRoute.subscribeContextChange((ctx) => {
      window.localStorage.setItem('aggregate-queue', ctx.filter.aggregateQueue ? 'true' : 'false');
    });
    const runningJobsRoute = routeService.findRouteByIds(['mainJobs'], jobsRoute);

    const sub = this.store.subscribe([RUNNING_JOBS_ENTITY, QUEUED_JOBS_ENTITY, MAIN_JOBS_ENTITY], ({
      [QUEUED_JOBS_ENTITY]: queuedJobs,
      [RUNNING_JOBS_ENTITY]: runningJobs,
      loading,
    }) => {
      if (!loading) {
        queuedJobsRoute.setBadge(queuedJobs.filter(job => !job.properties.parentId).length);
        runningJobsRoute.setBadge(runningJobs.filter(job => !job.properties.parentId).length);
      }
    }, { noInitUpdate: true });
    sub.badge = true;

    // Node badges
    const nodeRoute = routeService.findRouteByIds(['cluster', 'nodes']);
    this.store.subscribe([NODES_ENTITY], ({ nodes, loading }) => {
      if (!loading) {
        nodeRoute.setBadge(nodes ? nodes.length : 0);
      }
    }, { noInitUpdate: true }); // Do not load nodes because of this subscription,
    // specially beccause this method is invoked before the user could log in
    // resutlting in an 401 error message triggered in the app...

    // Batches badge
    const batchRoute = routeService.findRouteByIds(['batchesgroup', 'batches']);
    this.store.subscribe([BATCHES_ENTITY], ({ batches, loading }) => {
      if (!loading) {
        const runningBatches = batches.reduce((total, batch) => {
          return total + (batchService.isBatchFinished(batch) ? 0 : 1);
        }, 0);
        batchRoute.setBadge(runningBatches);
      }
    }, { noInitUpdate: true });

    // repository badge
    const repositoryRoute = routeService.findRouteByIds(['batchesgroup', 'repository']);
    const repoUploader = repositoryService.getUploader();
    // Batches fetching for badges
    const repoSub = {
      fileStatusChanged() {
        const uploadingNodes = repoUploader.getFileNodes().filter(n => n.isUploading());
        repositoryRoute.setBadge(uploadingNodes.length);
      },
    };
    repoUploader.subscribeUpload(repoSub);
  }
}
