import React, { useState } from 'react';
import LoginPage from 'grb-webui/components/LoginPage';

export default ({ authService }) => {
  const Page = (props) => {
    const [LoginComponent] = useState(() => {
      return LoginPage({
        userLoginTitle: 'Log in',
        userLinkText: 'User Log in',
        userLoginButtonText: 'Log in',
        ...(authService.isGrbProvider() ? {} : {
          adminLogin: true,
          adminLoginTitle: 'Log in',
          adminLinkText: 'System Administrator Log in',
          adminLoginButtonText: 'Log in as sysadmin',
        }),
      });
    });

    return <LoginComponent {...props} />;
  };

  return Page;
};
