import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import NameIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import RoleIcon from '@material-ui/icons/SupervisorAccount';
import PasswordCheckedIcon from '@material-ui/icons/Check';
import AccessIcon from '@material-ui/icons/LockOpen';
import { useStoreData } from 'grb-webui/store';
import PasswordIcon from '../../components/PasswordIcon';
import PasswordValidationRules from '../../components/PasswordValidationRules';
import { SETTINGS_ENTITY } from '../../Entities';
import './EditUserDialog.scss';

const IconRow = ({ Icon, children }) => {
  return (
    <div className="grb-edit-user-row-icon">
      <header>
        {Icon && <Icon />}
      </header>
      {children}
    </div>
  );
};

IconRow.propTypes = {
  Icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({}),
  ]),
  children: PropTypes.node.isRequired,
};

IconRow.defaultProps = {
  Icon: undefined,
};

const PasswordCheck = ({ hidden, disabled }) => {
  return (
    <PasswordCheckedIcon
      className={classnames(
        'grb-gutter-left',
        'grb-create-user-dialog-password-checked',
        hidden && 'grb-hidden',
        disabled && 'grb-disabled',
      )}
    />
  );
};

PasswordCheck.propTypes = {
  hidden: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

PasswordCheck.defaultProps = {
  disabled: false,
};

const USERNAME_PROPERTY = 'username';
const FIRSTNAME_PROPERTY = 'firstname';
const LASTNAME_PROPERTY = 'lastname';
const EMAIL_PROPERTY = 'email';
const ROLE_PROPERTY = 'role';
const PASSWORD_PROPERTY = 'password';
const API_KEYS_DISABLED_PROPERTY = 'apiKeysDisabled';
const TOKEN_DISABLED_PROPERTY = 'tokenDisabled';
const AUTH_PROPERTY = 'auth';
const ALL_USER_PROPERTIES = [
  USERNAME_PROPERTY,
  FIRSTNAME_PROPERTY,
  LASTNAME_PROPERTY,
  EMAIL_PROPERTY,
  ROLE_PROPERTY,
  PASSWORD_PROPERTY,
  API_KEYS_DISABLED_PROPERTY,
  TOKEN_DISABLED_PROPERTY,
  AUTH_PROPERTY,
];

const EditUserDialogHOC = () => {
  const validateName = (name) => {
    if (!name) {
      return 'Must not be empty';
    }
    return null;
  };

  const validateUsername = (name, user) => {
    if (!name) {
      return 'Must not be empty';
    }
    if (user && (user.username === name)) {
      return 'Must enter a different username';
    }
    return null;
  };

  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const validateEmail = (email) => {
    return re.test(String(email).toLowerCase()) ? null : 'Invalid email address';
  };

  const validateEditProfile = (profile, authService) => {
    const message = authService.validateEditProfile(profile);
    return { message, canEditProfile: !message };
  };

  const EditUserDialog = (props) => {
    const {
      title,
      onClose,
      open,
      user,
      nonEditableProps,
      editableProps,
      userService,
      settings,
      settingsService,
      authService,
    } = props;
    const properties = (editableProps || ALL_USER_PROPERTIES)
      .filter(p => (!nonEditableProps || (nonEditableProps.indexOf(p) < 0)))
      .reduce((obj, p) => { obj[p] = true; return obj; }, {});
    const hasProperties = (...pps) => {
      return pps.find(p => properties[p]) ? true : undefined;
    };
    const [editedUser, setEditedUser] = useState(user ? { ...user, password: '' } : { role: 1 });
    const [{ message, canEditProfile }, setEditProfile] = useState(() => validateEditProfile(editedUser, authService));

    const validate = useCallback((o) => {
      const errors = {
        username: hasProperties(USERNAME_PROPERTY)
          && validateUsername(o.username, user),
        firstname: hasProperties(FIRSTNAME_PROPERTY) && validateName(o.firstname),
        lastname: hasProperties(LASTNAME_PROPERTY) && validateName(o.lastname),
        email: hasProperties(EMAIL_PROPERTY) && validateEmail(o.email),
        password: hasProperties(PASSWORD_PROPERTY)
          && (!hasProperties(AUTH_PROPERTY) || !o.tokenDisabled)
          && settingsService.validatePassword(o.password, settings),
        password2: hasProperties(PASSWORD_PROPERTY)
          && (!hasProperties(AUTH_PROPERTY) || !o.tokenDisabled)
          && (o.password !== o.password2 ? 'Confirmed password does not match password' : null),
        auth: hasProperties(AUTH_PROPERTY) && (o.apiKeysDisabled && o.tokenDisabled)
          ? 'User must be given at least one authentication access' : null,
      };
      return errors;
    }, [settings, settingsService, hasProperties, user]);

    const [errors, setErrors] = useState(validate(editedUser, hasProperties));

    useEffect(() => {
      const newUser = user ? { ...user } : { role: 1 };
      setEditedUser(newUser);
      setErrors(validate(newUser, hasProperties));
      setEditProfile(validateEditProfile(newUser, authService));
    }, [user, nonEditableProps, editableProps, authService]);

    const handleCancel = () => {
      onClose(undefined);
    };

    const handleOk = () => {
      if (user) {
        onClose(user, editedUser);
      } else {
        onClose(editedUser);
      }
    };

    const editValue = (propName, newValue) => {
      const newUser = { ...editedUser, [propName]: newValue };
      setEditedUser(newUser);
      setErrors(validate(newUser, hasProperties));
      setEditProfile(validateEditProfile(newUser, authService));
    };

    const handleEdit = propName => (evt) => { editValue(propName, evt.target.value); };

    const handleAPIKeysAccess = (e) => {
      editValue('apiKeysDisabled', !e.target.checked);
    };
    const handleLoginAccess = (e) => {
      editValue('tokenDisabled', !e.target.checked);
    };

    const canApply = !Object.keys(errors).find(k => errors[k])
      && (!user || Boolean(Object.keys(editedUser).find(k => (user[k] !== editedUser[k]))));

    const handleKeyEvent = (ev) => {
      if (ev.key === 'Enter') {
        ev.preventDefault();
        if (canApply) {
          handleOk();
        }
      }
    };

    const PwdInputProps = { autoComplete: 'new-password' };
    const errorFields = ALL_USER_PROPERTIES.filter(p => properties[p] && errors[p]);
    if (errors.password2) {
      errorFields.push('password2');
    }
    const displayedErrors = {};
    if (errorFields.length) {
      displayedErrors[errorFields[0]] = errors[errorFields[0]];
    }
    // const displayedErrors = errors;
    const valEditRole = user ? userService.validateEditUserRole(user) : true;
    const editRoleRightsError = ((valEditRole !== null) && (valEditRole !== true)) ? valEditRole : undefined;
    const pwd = editedUser[PASSWORD_PROPERTY] || '';

    const passwordFields = hasProperties(PASSWORD_PROPERTY) && (
      <div className={`grb-create-user-dialog-authentication-passwords${!hasProperties(API_KEYS_DISABLED_PROPERTY) && ' grb-create-user-dialog-authentication-passwords-standalone' || ''}`}>
        <div className="grb-create-user-dialog-authentication-password-check">
          <TextField
            id="create-user-dialog-password-field"
            label="Password"
            type="password"
            value={editedUser[PASSWORD_PROPERTY] || ''}
            onChange={handleEdit(PASSWORD_PROPERTY)}
            InputProps={PwdInputProps}
            fullWidth
            autoFocus={Object.keys(properties).length === 1}
            disabled={editedUser.tokenDisabled}
          />
          <PasswordCheck hidden={Boolean(errors.password || editedUser.tokenDisabled)} />
        </div>
        {!editedUser.tokenDisabled && Boolean(displayedErrors.password) && (
          <PasswordValidationRules
            password={pwd}
            settingsService={settingsService}
            settings={settings}
          />
        )}
        <div className="grb-create-user-dialog-authentication-password-check grb-gutter-top">
          <TextField
            id="create-user-dialog-password2-field"
            error={!editedUser.tokenDisabled && Boolean(displayedErrors.password2)}
            label={(errors.password || errors.password2) ? 'Confirm password' : 'Confirmed password'}
            type="password"
            value={editedUser.password2 || ''}
            onChange={handleEdit('password2')}
            helperText={!editedUser.tokenDisabled && displayedErrors.password2 || ' '}
            InputProps={PwdInputProps}
            disabled={editedUser.tokenDisabled}
            fullWidth
          />
          <PasswordCheck
            hidden={Boolean(errors.password || errors.password2 || editedUser.tokenDisabled)}
          />
        </div>
      </div>
    );

    return (
      <Dialog
        disableBackdropClick
        aria-labelledby="grb-create-user-title"
        className={classnames('grb-create-user-dialog', 'grb-form-dialog', user && 'grb-edit-user-dialog')}
        onClose={handleCancel}
        open={open}
        onKeyPress={handleKeyEvent}
      >
        <DialogTitle id="grb-create-user-title">{title}</DialogTitle>
        <DialogContent className="grb-create-user-dialog-content grb-form-dialog-content">
          {/* Message section */}
          {!!message && (
            <header className="grb-create-user-dialog-header">{message}</header>
          )}
          {/* Name section */}
          {hasProperties(USERNAME_PROPERTY, FIRSTNAME_PROPERTY, LASTNAME_PROPERTY) && (
            <section>
              {hasProperties(USERNAME_PROPERTY) && (
                <IconRow Icon={NameIcon}>
                  <TextField
                    id="create-user-dialog-name-field"
                    error={Boolean(displayedErrors[USERNAME_PROPERTY])}
                    label="Username"
                    value={editedUser.username || ''}
                    onChange={handleEdit(USERNAME_PROPERTY)}
                    helperText={displayedErrors[USERNAME_PROPERTY] || ' '}
                    fullWidth
                    autoFocus
                  />
                </IconRow>
              )}
              {hasProperties(FIRSTNAME_PROPERTY) && (
                <IconRow Icon={hasProperties(USERNAME_PROPERTY) ? undefined : NameIcon}>
                  <div className="grb-create-user-dialog-names">
                    <TextField
                      id="create-user-dialog-firstname-field"
                      error={Boolean(displayedErrors[FIRSTNAME_PROPERTY])}
                      label="Firstname"
                      value={editedUser.firstname || ''}
                      onChange={handleEdit(FIRSTNAME_PROPERTY)}
                      helperText={displayedErrors[FIRSTNAME_PROPERTY] || ' '}
                      className="grb-create-user-dialog-firstname"
                      autoFocus={!hasProperties(USERNAME_PROPERTY)}
                      disabled={!canEditProfile}
                    />
                    <TextField
                      id="create-user-dialog-lastname-field"
                      error={Boolean(displayedErrors[LASTNAME_PROPERTY])}
                      label="Lastname"
                      value={editedUser.lastname || ''}
                      className="grb-create-user-dialog-lastname"
                      onChange={handleEdit(LASTNAME_PROPERTY)}
                      helperText={displayedErrors[LASTNAME_PROPERTY] || ' '}
                      disabled={!canEditProfile}
                    />
                  </div>
                </IconRow>
              )}
            </section>
          )}

          {/* Email section */}
          {hasProperties(EMAIL_PROPERTY) && (
            <section>
              <IconRow Icon={EmailIcon}>
                <TextField
                  id="create-user-dialog-email-field"
                  error={Boolean(displayedErrors[EMAIL_PROPERTY])}
                  label="Email"
                  value={editedUser.email || ''}
                  onChange={handleEdit(EMAIL_PROPERTY)}
                  helperText={displayedErrors[EMAIL_PROPERTY] || ' '}
                  fullWidth
                  disabled={!canEditProfile}
                />
              </IconRow>
            </section>
          )}

          {/* Role section */}
          {hasProperties(ROLE_PROPERTY) && (
            <section>
              <IconRow Icon={RoleIcon}>
                <FormControl
                  error={displayedErrors[ROLE_PROPERTY]}
                  className="grb-flex"
                  disabled={!!editRoleRightsError}
                >
                  <InputLabel id="create-user-dialog-role-label">Role</InputLabel>
                  <Select
                    labelId="create-user-dialog-role-label"
                    id="create-user-dialog-role"
                    value={editedUser.role}
                    onChange={handleEdit(ROLE_PROPERTY)}
                  >
                    <MenuItem value={0} key="READONLY">READONLY</MenuItem>
                    <MenuItem value={1} key="STANDARD">STANDARD</MenuItem>
                    <MenuItem value={2} key="ADMIN">ADMIN</MenuItem>
                    <MenuItem value={3} key="SYSADMIN">SYSADMIN</MenuItem>
                  </Select>
                  <FormHelperText>
                    {displayedErrors[ROLE_PROPERTY] || editRoleRightsError || ' '}
                  </FormHelperText>
                </FormControl>
              </IconRow>
            </section>
          )}
          {/* Password section */}
          {!hasProperties(API_KEYS_DISABLED_PROPERTY) && hasProperties(PASSWORD_PROPERTY) && (
            <section className="grb-multilines-section">
              <IconRow Icon={PasswordIcon}>
                {passwordFields}
              </IconRow>
            </section>
          )}
          {/* Access type section */}
          {hasProperties(API_KEYS_DISABLED_PROPERTY) && (
            <section>
              <IconRow Icon={AccessIcon}>
                <FormLabel component="legend" className="grb-create-user-dialog-authentication-title">
                  User Authentication
                </FormLabel>
              </IconRow>
              <IconRow>
                <FormControl
                  component="fieldset"
                  className="grb-create-user-dialog-authentication"
                  error={Boolean(errors.auth)}
                >
                  <FormGroup>
                    <FormHelperText>{errors.auth || ''}</FormHelperText>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={!editedUser.tokenDisabled}
                          onChange={handleLoginAccess}
                          name="InteractiveLoginAccess"
                          id="InteractiveLoginAccess"
                        />
                      )}
                      label="Interactive Login"
                    />
                    <div className="grb-create-user-dialog-check-indent">
                      <Typography variant="body1" className="grb-create-user-dialog-authentication-desc">
                        Use username and password to access the manager and command line tools
                      </Typography>
                      {passwordFields}
                    </div>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={!editedUser.apiKeysDisabled}
                          onChange={handleAPIKeysAccess}
                          name="APIKeyAccess"
                          id="APIKeyAccess"
                        />
                      )}
                      label="API Keys"
                    />
                    <div className="grb-create-user-dialog-check-indent">
                      <Typography variant="body1" className="grb-create-user-dialog-authentication-desc">
                        Use an API key and secret to integrate applications with programmatic access
                      </Typography>
                    </div>
                  </FormGroup>
                </FormControl>
              </IconRow>
            </section>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary" id="cancel-create-user">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary" id="apply-create-user" disabled={!canApply}>
            {user ? 'Apply' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  EditUserDialog.propTypes = {
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({
      username: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
      role: PropTypes.number,
    }),
    settings: PropTypes.shape({}).isRequired,
    settingsService: PropTypes.shape({
      passwordRules: PropTypes.func,
      validatePassword: PropTypes.func,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    nonEditableProps: PropTypes.arrayOf(PropTypes.string),
    editableProps: PropTypes.arrayOf(PropTypes.string),
    userService: PropTypes.shape({
      validateEditUserRole: PropTypes.func,
    }).isRequired,
    authService: PropTypes.shape({
      validateEditProfile: PropTypes.func,
    }).isRequired,
  };

  EditUserDialog.defaultProps = {
    user: undefined,
    nonEditableProps: undefined,
    editableProps: undefined,
  };

  return (props) => {
    const pps = props;
    const { settings } = useStoreData(pps.store, [SETTINGS_ENTITY]);
    return settings
      ? <EditUserDialog {...props} settings={settings} />
      : (null);
  };
};

export {
  EditUserDialogHOC as default,
  USERNAME_PROPERTY,
  FIRSTNAME_PROPERTY,
  LASTNAME_PROPERTY,
  EMAIL_PROPERTY,
  PASSWORD_PROPERTY,
  ALL_USER_PROPERTIES,
};
