import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import AliveIcon from '@material-ui/icons/RadioButtonUnchecked';
import InvalidIcon from '@material-ui/icons/HighlightOff';
import NoneIcon from '@material-ui/icons/Block';
import './LicenseStatus.scss';

const NONE = 0;
const ALIVE = 1;
const INVALID = 2;
const EXPIRED = 3;

const Tooltips = [];
Tooltips[NONE] = 'NONE';
Tooltips[ALIVE] = 'ALIVE';
Tooltips[INVALID] = 'INVALID';
Tooltips[EXPIRED] = 'EXPIRED';

const StatusClassnames = [];
StatusClassnames[NONE] = 'status-none';
StatusClassnames[ALIVE] = 'status-alive';
StatusClassnames[INVALID] = 'status-invalid';
StatusClassnames[EXPIRED] = 'status-expired';

const LicenseStatus = ({ value, object: license }) => {
  // value = LEAVING; // eslint-disable-line
  const { error, errorMsg } = license;
  let Icon;
  switch (value) {
    case ALIVE:
      Icon = <AliveIcon />;
      break;
    case INVALID:
      Icon = <InvalidIcon />;
      break;
    case EXPIRED:
      // Icon = <ExpiredIcon />;
      Icon = (
        <svg style={{ width: '24px', height: '24px', overflow: 'visible' }} viewBox="0 0 24 24">
          <path style={{ textIndent: 0, textAlign: 'start', lineHeight: 'normal', textTransform: 'none', blockProgression: 'tb', fontFamily: 'Bitstream Vera Sans' }} d="M 12 4 C 7.5700855 4 4 7.5700855 4 12 C 4 16.153045 7.1451416 19.560306 11.1875 19.96875 L 8.125 25.5 L 7.3125 27 L 9 27 L 27 27 L 28.6875 27 L 27.875 25.5 L 19.96875 11.28125 C 19.514973 7.2063879 16.138023 4 12 4 z M 12 6 C 14.256999 6 16.187087 7.2505871 17.21875 9.125 L 17.125 9.3125 L 12.3125 17.96875 C 12.21287 17.980853 12.157808 18 12 18 C 8.6299145 18 6 15.370085 6 12 C 6 8.6299145 8.6299145 6 12 6 z M 11 8 L 11 12.5625 L 9.28125 14.28125 L 10.71875 15.71875 L 12.71875 13.71875 L 13 13.40625 L 13 13 L 13 8 L 11 8 z M 18 11.84375 L 25.28125 25 L 10.71875 25 L 18 11.84375 z M 17 16 L 17 21 L 19 21 L 19 16 L 17 16 z M 17 22 L 17 24 L 19 24 L 19 22 L 17 22 z" />
        </svg>
      );
      break;
    default:
      Icon = <NoneIcon />;
      break;
  }
  return (
    <Tooltip
      title={!error ? (
        Tooltips[value]
      ) : (
        <React.Fragment>
          {Tooltips[value]}<br />
          {errorMsg}
        </React.Fragment>
      )}
    >
      <div className={`grb-licensestatus ${StatusClassnames[value]}`}>
        {Icon}
      </div>
    </Tooltip>
  );
};

LicenseStatus.propTypes = {
  value: PropTypes.number,
  object: PropTypes.shape({}),
};

LicenseStatus.defaultProps = {
  value: 0,
  object: undefined,
};

export default LicenseStatus;
