const NODES_TYPE = 'Nodes';
const JOBS_LINK_TYPE = 'JobsLink';
const LICENSES_TYPE = 'Licenses';
const EDIT_PROFILE_TYPE = 'EditProfile';
const USER_API_KEYS_TYPE = 'UserAPIKeys';
const API_KEYS_TYPE = 'APIKeys';
const USERS_TYPE = 'Users';
const REPOSITORY_TYPE = 'Repository';
const MAIN_JOBS_TYPE = 'MainJobs';
const QUEUED_JOBS_TYPE = 'QueuedJobs';
const HISTORY_JOBS_TYPE = 'HistoryJobs';
const SETTINGS_TYPE = 'Settings';

export {
  NODES_TYPE,
  JOBS_LINK_TYPE,
  LICENSES_TYPE,
  EDIT_PROFILE_TYPE,
  USER_API_KEYS_TYPE,
  API_KEYS_TYPE,
  USERS_TYPE,
  REPOSITORY_TYPE,
  MAIN_JOBS_TYPE,
  QUEUED_JOBS_TYPE,
  HISTORY_JOBS_TYPE,
  SETTINGS_TYPE,
};
