import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import SuccessItemIcon from '@material-ui/icons/Check';
import ErrorItemIcon from '@material-ui/icons/PriorityHigh';
import './TestResultDialog.scss';

const TestResultDialog = ({
  onClose,
  open,
  dialogTitle,
  errorTitle,
  successTitle,
  results,
}) => {
  return (
    <Dialog
      disableBackdropClick
      aria-labelledby="grb-ldap-test-result-dialog-title"
      className="grb-ldap-test-result-dialog grb-form-dialog"
      onClose={onClose}
      open={open}
    >
      <DialogTitle id="grb-ldap-test-result-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent className={classnames(
          'grb-ldap-test-result-dialog-content',
          'grb-form-dialog-content',
          results.valid ? 'grb-ldap-test-result-dialog-success' : 'grb-ldap-test-result-dialog-error',
        )}
      >
        <header>
          {results.valid ? <SuccessIcon style={{ fontSize: 40 }} /> : <ErrorIcon style={{ fontSize: 40 }} />}
          <Typography variant="h6">
            {results.valid ? successTitle : errorTitle}
          </Typography>
        </header>
        <List>
          {results.messages.map(({ message, isError }) => (
            <ListItem className={`grb-ldap-test-result-item ${isError ? 'grb-ldap-test-result-error' : 'grb-ldap-test-result-success'}`}>
              <ListItemIcon>
                {isError ? <ErrorItemIcon /> : <SuccessItemIcon />}
              </ListItemIcon>
              <ListItemText primary={message} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions className="grb-form-longtext-dialog-buttons">
        <Button
          onClick={onClose}
          id="close-ldap-test-result"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TestResultDialog.propTypes = {
  results: PropTypes.shape({
    valid: PropTypes.bool,
    messages: PropTypes.arrayOf(PropTypes.shape({
      message: PropTypes.string,
      isError: PropTypes.bool,
    })),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  errorTitle: PropTypes.string,
  successTitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
};

TestResultDialog.defaultProps = {
  errorTitle: 'Test failed',
  successTitle: 'Test failed',
};

export default TestResultDialog;
