import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function EditNodeDialog({ onClose, node, open }) {
  const [jobLimit, setJobLimit] = React.useState(node.jobLimit);

  function validateJobLimit(l) {
    if (typeof l === 'string') {
      return 'Specified job limit is not a number';
    }
    if (l < 1) {
      return 'Job limit must be greater or equal to 1';
    }
    return undefined;
  }
  const [jobLimitError, setJobLimitError] = React.useState(validateJobLimit(jobLimit));

  function handleApply() {
    onClose(node, { jobLimit });
  }

  function handleClose() {
    onClose();
  }

  const handleLimitChange = (e) => {
    let l = e.target.value;
    if (typeof l === 'string') {
      const n = parseInt(l, 10);
      if (!Number.isNaN(n)) {
        l = n;
      }
    }
    setJobLimitError(validateJobLimit(l));
    setJobLimit(l);
  };

  const JobLimitInputProps = { inputProps: { min: 1, style: { textAlign: 'right' } } };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="node-dialog-title" id="edit-node">
      <DialogTitle id="node-dialog-title">Set Job Limit</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The job limit indicates how many jobs can be executed concurrently.
        </DialogContentText>
        <TextField
          autoFocus
          error={jobLimitError}
          margin="dense"
          id="joblimit"
          label="Job limit"
          type="number"
          value={jobLimit}
          onChange={handleLimitChange}
          InputProps={JobLimitInputProps}
          helperText={jobLimitError || undefined}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" id="cancel-edit-node">
          Cancel
        </Button>
        <Button onClick={handleApply} color="primary" disabled={!!jobLimitError} id="apply-edit-node">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditNodeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.string,
    jobLimit: PropTypes.number,
  }).isRequired,
  open: PropTypes.bool.isRequired,
};
