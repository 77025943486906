import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import './LoginDialog.scss';

const validateUsername = (s) => {
  return !s ? 'Username cannot be empty' : null;
};

const validatePassword = (s) => {
  return !s ? 'Password cannot be empty' : null;
};

const LoginDialog = ({ onClose, open, title, useUsername, usePassword }) => {
  const [{ username, usernameError }, setUsername] = useState({
    username: '', usernameError: useUsername && validateUsername(''),
  });
  const [{ password, passwordError }, setPassword] = useState({
    password: '', passwordError: usePassword && validatePassword(''),
  });

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    const credentials = usePassword
      && (useUsername ? { username, password } : { password })
      || (useUsername ? { username } : {});
    onClose(credentials);
  };

  const handleChangeUsername = (e) => {
    setUsername({ username: e.target.value, usernameError: validateUsername(e.target.value) });
  };

  const handleChangePassword = (e) => {
    setPassword({ password: e.target.value, passwordError: validatePassword(e.target.value) });
  };

  return (
    <Dialog
      disableBackdropClick
      aria-labelledby="grb-ldap-login-dialog-title"
      className="grb-ldap-login-dialog grb-form-dialog"
      onClose={handleCancel}
      open={open}
    >
      <DialogTitle id="grb-ldap-login-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent className="grb-ldap-login-dialog-content grb-form-dialog-content">
        {useUsername && (
          <TextField
            className="grb-ldap-login-dialog-username"
            label="Username"
            fullWidth
            error={!!usernameError}
            helperText={usernameError || ''}
            required
            onChange={handleChangeUsername}
          />
        )}
        {usePassword && (
          <TextField
            className="grb-ldap-login-dialog-password"
            label="Password"
            type="password"
            fullWidth
            error={!!passwordError}
            helperText={passwordError || ''}
            required
            onChange={handleChangePassword}
          />
        )}
      </DialogContent>
      <DialogActions className="grb-form-longtext-dialog-buttons">
        <Button
          onClick={handleCancel}
          color="primary"
          id="cancel-ldap-login"
        >
          Cancel
        </Button>
        <Button
          onClick={handleOk}
          color="primary"
          variant="contained"
          id="apply-ldap-login"
          disabled={!!usernameError || !!passwordError}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LoginDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  useUsername: PropTypes.bool,
  usePassword: PropTypes.bool,
};

LoginDialog.defaultProps = {
  title: 'Login',
  useUsername: undefined,
  usePassword: undefined,
};

export default LoginDialog;
