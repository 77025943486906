import { hot } from 'react-hot-loader/root';
import React from 'react';
import ReactDOM from 'react-dom';
import AppRoot from 'tuc-webui-base/BaseRoot';
import GrbPlugin from 'grb-webui/GrbPlugin';
import GrbrsmPlugin from './grbrsm_ui/GrbrsmPlugin';

const plugins = [GrbPlugin, GrbrsmPlugin];

const Root = (props) => {
  const params = {
    jsonConfig: window.grbConfig,
    schema: window.grbSchema,
    ...props,
  };
  return (
    <AppRoot plugins={plugins} {...params} />
  );
};

// https://github.com/gaearon/react-hot-loader/issues/1294 for infinite reloading
const hotRoot = hot(Root);

const render = (Component) => {
  ReactDOM.render(
    <Component />,
    document.getElementById('root'),
  );
};

render(hotRoot);
