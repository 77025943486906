import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import './NodeButtons.scss';

const NodeButtons = ({
  row: node,
  nodeService,
  onToggleNode,
  onEditNode,
  canInteractNode,
}) => {
  if (!canInteractNode) {
    return (null);
  }
  const started = !nodeService.canStartNode(node);
  const handleEditNode = () => {
    onEditNode(node);
  };
  const handleStartStopNode = () => {
    onToggleNode(node, !started);
  };
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={handleEditNode}
        id={`grb-node-button-limit-${node.id}`}
      >
        Job limit
      </Button>
      <Button
        className="grbrsm-node-start-stop"
        variant="outlined"
        onClick={handleStartStopNode}
        id={`grb-node-button-toggle-${node.id}`}
      >
        {started ? 'Stop' : 'Start'}
      </Button>
    </React.Fragment>
  );
};

NodeButtons.propTypes = {
  nodeService: PropTypes.shape({
    canStartNode: PropTypes.func.isRequired,
  }).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string,
  }),
  onEditNode: PropTypes.func.isRequired,
  onToggleNode: PropTypes.func.isRequired,
  canInteractNode: PropTypes.bool.isRequired,
};

NodeButtons.defaultProps = {
  row: {},
};

export default NodeButtons;

