import JobsTablePage from 'grb-admin-console/pages/jobs/JobsTablePage';
import JobsQueuePage from 'grb-admin-console/pages/jobs/JobsQueuePage';
import { JobDashboardInfo } from 'grb-admin-console/pages/jobs';
import {
  MAIN_JOBS_TYPE,
  QUEUED_JOBS_TYPE,
  HISTORY_JOBS_TYPE,
} from 'grb-admin-console/ComponentTypes';
import {
  MAIN_JOBS_ENTITY,
  QUEUED_JOBS_ENTITY,
  HISTORY_JOBS_ENTITY,
} from 'grb-admin-console/Entities';
import JobObjectiveDashboardTile from './JobObjectiveDashboardTile';
import './JobDashboard.scss';

const MainJobsPage = (jobOptions) => {
  return JobsTablePage({
    ...jobOptions,
    jobType: MAIN_JOBS_TYPE,
    jobsEntity: MAIN_JOBS_ENTITY,
    entities: [MAIN_JOBS_ENTITY],
    rowButtons: ['jobDashboard'],
    componentsByName: {
      JobObjectiveDashboardTile,
    },
  });
};

const QueuedJobsPage = (jobOptions) => {
  return JobsQueuePage({
    ...jobOptions,
    jobType: QUEUED_JOBS_TYPE,
    jobsEntity: QUEUED_JOBS_ENTITY,
    entities: [QUEUED_JOBS_ENTITY],
  });
};

const HistoryJobsPage = (jobOptions) => {
  return JobsTablePage({
    ...jobOptions,
    jobType: HISTORY_JOBS_TYPE,
    jobsEntity: HISTORY_JOBS_ENTITY,
    serverFiltering: true,
    entities: [{ name: HISTORY_JOBS_ENTITY, getQuery: pps => pps.serverQuery }],
    rowButtons: ['jobDashboard'],
    componentsByName: {
      JobObjectiveDashboardTile,
    },
  });
};

const JobDashboard = (jobOptions) => {
  return JobDashboardInfo({
    ...jobOptions,
    jobType: MAIN_JOBS_TYPE, // Type of jobs does not matter in this context
    jobsEntity: MAIN_JOBS_ENTITY,
    componentsByName: {
      JobObjectiveDashboardTile,
    },
    className: 'grb-rsm-job-dashboard',
  });
};

export { QueuedJobsPage, MainJobsPage, HistoryJobsPage, JobDashboard };
