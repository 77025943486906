import axios from 'axios';

function defaultErrorFct(err) {
  console.log(err);
}

const getRows = (path, rows) => {
  return rows;
};

class DataService {
  constructor(config) {
    this.schemaService = config.schemaService;
    this.dataByPaths = {};
    const schemaPaths = config.schemaService.getPaths();
    Object.keys(schemaPaths).forEach((pathName) => {
      const schemaPath = schemaPaths[pathName];
      const p = {};
      Object.keys(schemaPath).forEach((method) => {
        const methodObj = schemaPath[method];
        p[method] = Object.create(methodObj);
      });
      this.dataByPaths[pathName] = p;
    });
    this.apiClient = config.apiClient;
    this.listeners = [];
    this.globalProps = {};
  }

  cacheData(path, data, method = 'get') {
    this.dataByPaths[path][method].data = data;
  }

  clearCacheData(path, method = 'get') {
    delete this.dataByPaths[path][method].data;
  }

  getDataCache(path, method = 'get') {
    const pathInfo = this.dataByPaths[path][method];
    return pathInfo && pathInfo.data;
  }

  setGlobalProperty(name, value) {
    // name can be a property name such as X-GUROBI-CSPASSWORD
    this.globalProps[name] = value;
  }

  request(path, method = 'get', payload, props, ensureDefaultValue = false) {
    const pathInfo = this.dataByPaths[path][method];
    const { data } = pathInfo;
    // If data is already fetched or fetching
    if (data) {
      // Check if data is a promise
      // https://stackoverflow.com/questions/27746304/how-do-i-tell-if-an-object-is-a-promise
      if (data.then) {
        return data;
      }
      // Data has been cached
      return Promise.resolve(data);
    }
    // Make a new request
    let reqProps;
    const config = { method };
    if (pathInfo.compiledPath || pathInfo.headers || pathInfo.body || pathInfo.query) {
      reqProps = Object.assign({}, this.globalProps, props);
    }
    // Get the URL
    if (pathInfo.compiledPath) {
      let paramIndex = 0;
      config.url = pathInfo.compiledPath.map((p) => {
        if (p) return p;
        const paramName = pathInfo.params[paramIndex];
        paramIndex += 1;
        return reqProps[paramName];
      }).join('');
    } else {
      config.url = path;
    }
    // Headers
    if (pathInfo.headers) {
      config.headers = {};
      pathInfo.headers.forEach((header) => {
        config.headers[header] = reqProps[header];
      });
    }
    // Body
    if (pathInfo.body) {
      config.data = payload || reqProps[pathInfo.body[0]];
    }
    // Query parameters
    if (pathInfo.query) {
      config.params = {};
      pathInfo.query.forEach((param) => {
        if (reqProps[param] !== undefined) {
          config.params[param] = reqProps[param];
        }
      });
    }

    pathInfo.data = this.apiClient.request(config)
    // pathInfo.data = this.apiClient.request(config)
    .then((res) => {
      const resultInfo = pathInfo.result;
      if (!resultInfo) {
        // No result is expected from the request
        return res.data;
      }
      if (!resultInfo.schema) {
        // Response data is provided with the headers
        if (resultInfo.headers) {
          // Unlikely case response is provided in several headers
          if (resultInfo.length > 1) {
            const result = {};
            resultInfo.headers.forEach((header) => {
              result[header] = res.headers[header];
            });
            return result;
          }
          const header = resultInfo.headers[0];
          return res.headers[header];
        }
        return res.data;
      }
      if (!res.data || typeof res.data === 'string') {
        if (!ensureDefaultValue) return null;
        return this.getEmptyValue(path);
      }
      const rows = getRows(path, res.data);
      this.setData(path, rows);
      return rows;
      // this.setData(path, res.data);
      // return res.data;
    })
    .catch((err) => {
      delete pathInfo.data;
      this.catchAndRethrow(err);
    });
    return pathInfo.data; // Return the promise
  }

  setData(path, data) {
    this.listeners.forEach((listener) => {
      if (listener.onUpdateAll && listener.match(path)) {
        listener.onUpdateAll(path, data);
      }
    });
    this.cacheData(path, data);
    return data;
  }

  updateData(path, data, props) {
    return this.request(path, 'put', data, props);
  }

  updateRow(path, row, rowIndex, newRow, props) {
    // Todo Review completely how to access row object and how to fetch their id
    const id = this.getRowId(path, row, rowIndex);
    return this.request(`${path}/{id}`, 'put', newRow, { ...props, id }).then(() => {
      let array = this.getDataCache(path);
      if (array && array.length) {
        for (let index = 0; index < array.length; index += 1) {
          if (array[index] === row) {
            array = array.concat([]);
            array[rowIndex] = newRow;
            this.setData(path, array);
            break;
          }
        }
      }
    });
  }

  addRow(path, row, props) {
    return this.request(path, 'post', row, props).then((location) => {
      let id;
      if (location) {
        const index = location.lastIndexOf('/');
        id = location.substring(index + 1);
      } else {
        throw new Error(`No location header returned from post request to ${path}`);
      }
      let array = this.getDataCache(path);
      if (!array) {
        throw new Error(`Data not loaded for ${path}`);
      }
      array = array.slice();
      // Todo get ID from put schema
      array.push(Object.assign(row, { _id: id }));
      this.setData(path, 'get', array);
      return array;
    });
  }

  deleteRow(path, row, rowIndex, props) {
    const id = this.getRowId(path, row, rowIndex);
    // Todo get key property from put request
    return this.request(path, 'delete', row, { ...props, id }).then(() => {
      let array = this.getDataCache(path);
      if (array && array.length) {
        for (let index = 0; index < array.length; index += 1) {
          if (array[index] === row) {
            array = array.slice();
            array.splice(index, 1);
            break;
          }
        }
        this.setData(path, array);
      }
      return array;
    });
  }

  deleteRows(path, rows, props) {
    const ids = rows.map(row => this.getRowId(path, row));
    // Todo fetch id from put method
    const promises = ids.map(id => this.request(path, 'delete', null, { ...props, id }));
    return axios.all(promises)
    .then(() => {
      let array = this.getDataCache(path);
      if (array && array.length) {
        array = array.slice();
        let toDeleteCount = rows.length;
        for (let index = 0; index < array.length;) {
          if (ids.indexOf(array[index]._id) >= 0) {
            array.splice(index, 1);
            toDeleteCount -= 1;
            if (!toDeleteCount) break;
          } else {
            index += 1;
          }
        }
        this.setData(path, array);
      }
      return array;
    })
    .catch(this.catchAndRethrow);
  }

  getEmptyValue(path) {
    const schema = this.schemaService.getPathSchema(path);
    if (!schema) {
      return null;
    }
    if (schema.isArray) {
      return [];
    }
    const type = this.schemaService.getType(schema.type);
    const value = {};
    this.schemaService.getProperties(type, true).forEach((property) => {
      value[property.name] = type.properties[property].type === 'array' ? [] : {};
    });
    return value;
  }

  //
  // Data models
  //
  makeDataHandler(path, propertyName, props, dataUpdateCB, errorCB) {
    const schema = this.schemaService.getPathSchema(path);
    if (!schema) {
      // No schema defined
      throw new Error(`No schema defined for ${path}`);
    }
    if (!schema.isArray) {
      const type = this.schemaService.getType(schema.type);
      if (propertyName) {
        const property = type.properties[propertyName];
        if (property.type === 'array') {
          return this.makeArrayPropertyChangeHandlers(
            path, propertyName, props, dataUpdateCB, errorCB,
          );
        }
      }
      return this.makePropertyChangeHandlers(
        path, propertyName, props, dataUpdateCB, errorCB,
      );
    }
    return this.makeArrayChangeHandlers(path, props, dataUpdateCB, errorCB);
  }

  getRowId(path, object) {
    // Not used in this project for the moment
    // Todo, using info from 'put' method maybe
    if (object) {
      return (typeof object === 'string' ? object : object.id);
    }
    return null;
  }

  /**
   * Handler for access an array.
   * @param {*} path URI to the array.
   * @param {*} props context properties for requests to the array.
   * @param {*} dataUpdateCB Callback for data updates
   * @param {*} errorCB Callback if an error occurs.
   */
  makeArrayChangeHandlers(path, props, dataUpdateCB, errorCB) {
    return {
      onDeleteObject: (object, index) => {
        if (object && Array.isArray) {
          this.deleteRows(path, object, props).then(dataUpdateCB).catch(errorCB);
        } else {
          this.deleteRow(path, object, index, props).then(dataUpdateCB).catch(errorCB);
        }
      },
      onUpdateObject: (object, index, newObject) => {
        this.updateRow(path, object, index, newObject, props)
        .then(dataUpdateCB).catch(errorCB);
      },
      onAddObject: (newObject) => {
        this.addRow(path, newObject, props)
        .then(dataUpdateCB).catch(errorCB);
      },
    };
  }

  /**
   * Handler for a array property of a singleton object.
   * @param {*} path URI to the singleton object
   * @param {*} propertyName name of the array property
   * @param {*} props context properties for requests to the singleton object.
   * @param {*} dataUpdateCB Callback for data updates
   * @param {*} errorCB Callback if an error occurs.
   */
  makeArrayPropertyChangeHandlers(
    path,
    propertyName,
    props,
    dataUpdateCB,
    errorCB,
  ) {
    const copyArray = () => {
      const obj = this.dataByPaths[path].get.data;
      if (!obj) {
        return this.request(path, 'get', null, props)
        .then((newObj) => {
          return newObj[propertyName] ? newObj[propertyName].concat([]) : [];
        });
      }
      return Promise.resolve(
        obj[propertyName] ? obj[propertyName].concat([]) : [],
      );
    };
    function indexOf(array, object, index) {
      if (index !== undefined) return index;
      let i;
      if (typeof object === 'string') {
        for (i = 0; i < array.length; i += 1) {
          if (array[i]._id === object) {
            return i;
          }
        }
        throw new Error(`Cannot find object to delete or update with ID ${object} for ${path}[${propertyName}]`);
      } else {
        i = array.indexOf(object);
        if (i >= 0) return i;
        if (object._id) return indexOf(array, object._id);
        throw new Error(`Cannot find object to delete or update${JSON.stringify(object)} for ${path}[${propertyName}]`);
      }
    }
    const update = (array) => {
      const obj = Object.assign(
        {},
        this.dataByPaths[path].get.data,
        { [propertyName]: array },
      );
      return this.updateData(path, obj, props)
      .then(dataUpdateCB)
      .catch(errorCB || defaultErrorFct);
    };
    return {
      onDeleteObject: (object, index) => {
        copyArray().then((array) => {
          const isArray = Array.isArray(object);
          (isArray ? object : [object]).forEach((o) => {
            const i = indexOf(array, o, isArray ? undefined : index);
            if (i >= 0) {
              array.splice(i, 1);
            }
          });
          return array;
        }).then(update);
      },
      onUpdateObject: (object, index, newObject) => {
        copyArray().then((array) => {
          const i = indexOf(array, object, index);
          if (i >= 0) {
            const modifyArray = array;
            modifyArray[i] = newObject;
          }
          return array;
        }).then(update);
      },
      onAddObject: (newObject) => {
        copyArray().then((array) => {
          array.push(newObject);
          return array;
        }).then(update);
      },
    };
  }

  /**
   * Handler for singleton object.
   * @param {*} papath URI to the singleton object.
   * @param {*} propertyName name of the property accessed by this handler. If null, this handler accesses to all properties of the singleton.
   * @param {*} props context properties for requests to the singleton object.
   * @param {*} dataUpdateCB Callback for data updates
   * @param {*} errorCB Callback if an error occurs.
   */
  makePropertyChangeHandlers(
    path,
    propertyName,
    props,
    dataUpdateCB,
    errorCB,
  ) {
    const ensureObject = () => {
      const obj = this.dataByPaths[path].get.data;
      return obj ? Promise.resolve(obj) : this.request(path, 'get', null, props, true);
    };

    return {
      onPropertyChange: (property, value) => {
        ensureObject().then((obj) => {
          const copy = Object.assign({}, obj);
          if (propertyName) {
            const p = Object.assign({}, copy[propertyName]);
            p[property] = value;
            copy[propertyName] = p;
          } else {
            copy[property] = value;
          }
          return copy;
        })
        .then((copy) => {
          return this.updateData(path, copy, props);
        })
        .then(dataUpdateCB)
        .catch(errorCB);
      },
    };
  }

  // Listening data changes
  register(path, listener) {
    const dataListener = {
      path,
      listener,
      match(pathParam) {
        return path === pathParam;
      },
      onAddObject: listener.onAddObject ? listener.onAddObject.bind(listener) : undefined,
      onDeleteObject: listener.onDeleObject ? listener.onDeleteObject.bind(listener) : undefined,
      onUpdateObject: listener.onUpdateObject ? listener.onUpdateObject.bind(listener) : undefined,
      onUpdateAll: listener.onUpdateAll ? listener.onUpdateAll.bind(listener) : undefined,
    };
    this.listeners.push(dataListener);
  }

  unregister(path, listener) {
    this.listeners = this.listeners.filter((dataObserver) => {
      return (dataObserver.listener !== listener)
        || (path && path !== dataObserver.path);
    });
  }

  // Default value
  getDefaultValue(prop, data, context) {
    const { defaultValue } = prop;
    const { idProperty } = context || {};
    if (defaultValue !== undefined) {
      if (typeof (defaultValue) === 'function') {
        return defaultValue(data);
      }
      return defaultValue;
    }
    if ((prop.name === idProperty) || (prop.name === '_id')) {
      if (data && Array.isArray(data) && data.length) {
        const usedIds = data.map(obj => obj[prop.name]);
        let counter;
        let value;
        if ((prop.type === 'integer') || (prop.type === 'number')) {
          counter = 1;
          do {
            value = data[0] + counter;
            counter += 1;
          } while (usedIds.indexOf(value) >= 0);
        } else {
          // Remove trailing numbers from id of first data element
          const prefix = data[0][prop.name].replace(/\d+$/, '');
          counter = 2;
          do {
            value = `${prefix}${counter}`;
            counter += 1;
          } while (usedIds.indexOf(value) >= 0);
        }
        return value;
      }
      if ((prop.type === 'integer') || (prop.type === 'number')) {
        return 0;
      }
      return 'default';
    }
    if ((prop.type === 'integer') || (prop.type === 'number')) {
      return 0;
    }
    if (prop.type === 'string') {
      return '';
    }
    if (prop.type === 'array') {
      return [];
    }
    const schemaType = this.schemaService.getType(prop.type);
    if (schemaType) {
      if (schemaType.enum) {
        return schemaType.enum[0].value;
      }
    }
    return {};
  }

  // Validation
  validateValue(value, prop, data, context) {
    const { validators } = prop;
    if (!validators) {
      return '';
    }
    for (let i = 0; i < validators.length; i += 1) {
      const msg = validators[i](value, data, prop.name, context);
      if (msg) return msg;
    }
    return '';
  }

  // Errors
  setErrorHandler(onError) {
    this.catchAndRethrow = (err) => {
      onError(err);
      throw err;
    };
    this.catchAndRethrow = this.catchAndRethrow.bind(this);
  }
}

export default DataService;

